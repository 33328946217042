import { Box, BoxProps, styled } from '@mui/material';
import { FC } from 'react';

export interface ILayoutProps extends BoxProps {
	isDrawerOpen: boolean;
	headerSize?: number;
}

const drawerWidthOpen = 286;
const drawerWidthClosed = 196;
const exclude =['isDrawerOpen'];

export const LayoutBase: FC<ILayoutProps> = styled(Box as FC<ILayoutProps>, {
	shouldForwardProp: (prop: string) => !exclude.includes(prop),
})(({ theme, isDrawerOpen }) => ({
	width: `calc(100% - ${isDrawerOpen ? drawerWidthOpen : drawerWidthClosed}px)`,
	height: '100%',
	backgroundColor: theme.palette.common.white,
	position: 'relative',
	transition: 'all 0.2s ease-in-out',
	boxShadow: theme.shadows[10],
	borderRadius: '16px',
	display: 'flex',
}));