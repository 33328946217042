import { useCallback, useEffect, useState } from 'react';
import { notify, stateSetter } from '@/utils';
import { IProjectType, ProjectTypesReturnType } from './types';
import { deleteType, fetchTypes, patchTypes, postTypes, fetchTypesQuery } from './actions';

interface ILoading {
	all: boolean;
	delete: boolean;
	create: boolean;
	update: boolean;
}

export function useProjectTypes<T extends IProjectType | IProjectType[]>(
	getTypesOnRender: boolean | undefined = undefined,
	queryParam: string | undefined = undefined,
	getAll: boolean | undefined = undefined
): ProjectTypesReturnType<T> {
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState<ILoading>({ all: true, delete: false, create: false, update: false });
	const [page, setPage] = useState<number>(0);
	const [items, setItems] = useState<number>(5);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [totalItems, setTotalItems] = useState<number>(1);
	const [enableQuery, setEnableQuery] = useState<boolean>(false);

	const handleResponse = (pageParam: number, itemsParam: number): void => {
		setTotalPages(pageParam);
		setTotalItems(itemsParam);
	};

	const toggleLoading = (type: 'all' | 'delete' | 'create' | 'update', value: boolean): void => {
		setLoading((prevState) => ({
			...prevState,
			[type]: value,
		}));
	};

	const getProjectTypes = useCallback(
		async (pageParam: number, itemsParam: number) => {
			try {
				toggleLoading('all', true);
				const response = await fetchTypes(pageParam, itemsParam, handleResponse, getAll);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('all', false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getProjectTypesQuery = useCallback(
		async (queryParams: string, pageParam: number, itemsParam: number) => {
			try {
				!enableQuery && setEnableQuery(true);
				toggleLoading('all', true);
				const response = await fetchTypesQuery(
					queryParams, pageParam, itemsParam, handleResponse, getAll
				);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('all', false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const removeProjectTypes = useCallback(
		async (typeId: string, onSuccess?: () => void) => {
			try {
				toggleLoading('delete', true);
				const response = await deleteType(typeId);
				if (response === 200) {
					setData(currentData => {
						if (Array.isArray(currentData)) {
							return currentData.filter(FSP => FSP._id !== typeId) as T;
						}
						return currentData;
					});
					onSuccess && onSuccess();
				} else if (response === 400) {
					notify.error('Nu am reușit să ștergem tipul de proiect!');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('delete', false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const createProjectTypes = useCallback(
		async (typeObj: { name: string }, onSuccess?: () => void) => {
			try {
				toggleLoading('create', true);
				const response = await postTypes(typeObj);
				if (response === 201) {
					onSuccess && onSuccess();
				} else if (response === 400) {
					notify.error('Nu am reușit să adăugăm tipul de proiect!');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('create', false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const editProjectTypes = useCallback(
		async (typeId: string, updatedObject: { name: string }, onSuccess?: () => void) => {
			try {
				toggleLoading('update', true);
				const response = await patchTypes(typeId, updatedObject);
				if (response === 200) {
					onSuccess && onSuccess();
				} else if (response === 400) {
					notify.error('Nu am reușit să actualizăm tipul de proiect!');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('update', false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	useEffect(() => {
		if (getTypesOnRender && queryParam === undefined) {
			getProjectTypes(page, items);
		}
		// eslint-disable-next-line
	}, [getTypesOnRender, page, items]);

	useEffect(() => {
		if (queryParam) {
			getProjectTypesQuery(queryParam, page, items);
		} else if (enableQuery) {
			getProjectTypes(page, items);
		}
		// eslint-disable-next-line
	}, [queryParam, page, items]);

	return {
		data: data || ([] as IProjectType[] as T),
		setData: setData as stateSetter<T>,
		loading: loading.all,
		loadingDelete: loading.delete,
		loadingCreate: loading.create,
		loadingUpdate: loading.update,
		createProjectTypes,
		editProjectTypes,
		getProjectTypes,
		removeProjectTypes,
		items,
		page,
		setItems,
		setPage,
		totalItems,
		totalPages
	};
}