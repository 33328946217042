import { useCallback, useEffect, useState } from 'react';
import { deleteUser, fetchAppUsers, fetchQueryUsers, fetchSingleAppUser } from './actions';
import { AppUsersReturnType, IAppUser } from './types';
import { notify, stateSetter } from '@/utils';

export function useAppUsers<T extends IAppUser | IAppUser[]>(
	getAppUsersOnRender: boolean | undefined = undefined,
	appUserId: string | undefined = undefined,
	queryParam: string | undefined = undefined
): AppUsersReturnType<T> {
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [loadingCTA, setLoadingCTA] = useState<boolean>(false);
	const [enableQuery, setEnableQuery] = useState<boolean>(false);
	const [page, setPage] = useState<number>(0);
	const [items, setItems] = useState<number>(5);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [totalItems, setTotalItems] = useState<number>(1);

	const handleResponse = (pageParam: number, itemsParam: number): void => {
		setTotalPages(pageParam);
		setTotalItems(itemsParam);
	};

	const getAppUsers = useCallback(
		async (pageParam: number, itemsParam: number) => {
			try {
				setLoading(true);
				const response = await fetchAppUsers(pageParam, itemsParam, handleResponse);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getQueryUsers = useCallback(
		async (query: string, queryPage: number, queryItems: number) => {
			try {
				!enableQuery && setEnableQuery(true);
				setLoading(true);
				const response = await fetchQueryUsers(query, queryPage, queryItems, handleResponse);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getAppUser = useCallback(
		async (userId: string) => {
			try {
				setLoading(true);
				const response = await fetchSingleAppUser(userId);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const removeUser = useCallback(
		async (userId: string, onSuccess?: () => void) => {
			try {
				setLoadingCTA(true);
				const response = await deleteUser(userId);
				if (response === 200) {
					notify.success('Utilizatorul a fost șters!');
					onSuccess && onSuccess();
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingCTA(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	useEffect(() => {
		if (getAppUsersOnRender && queryParam === undefined) {
			getAppUsers(page, items);
		}
		// eslint-disable-next-line
	}, [getAppUsersOnRender, queryParam, page, items]);

	useEffect(() => {
		if (appUserId) {
			getAppUser(appUserId);
		}
		// eslint-disable-next-line
	}, [appUserId]);

	useEffect(() => {
		if (queryParam && appUserId === undefined) {
			getQueryUsers(queryParam, page, items);
		} else if (appUserId === undefined && enableQuery) {
			getAppUsers(page, items);
		}
		// eslint-disable-next-line
	}, [queryParam, appUserId, page, items]);

	return {
		data: data || ([] as IAppUser[] as T),
		setData: setData as stateSetter<T>,
		loading,
		getAppUser,
		getAppUsers,
		removeUser,
		loadingCTA,
		items,
		page,
		setItems,
		setPage,
		totalPages,
		totalItems
	};
}