import { createContext, useContext } from 'react';
import { IMapDrawContextType } from './types';

export const MapDrawContext = createContext<IMapDrawContextType>({
	drawStyle: 'LineString',
	setDrawStyle: () => {},
	isDraw: false,
	setIsDraw: () => {},
	setIsDrawStep: () => {},
});

export const useMapDrawContext = (): IMapDrawContextType => useContext(MapDrawContext);
