import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MapDrawContext } from './context';
import { DrawStyleType } from './types';

export const MapDrawProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [drawStyle, setDrawStyle] = useState<DrawStyleType>('LineString');
	const [isDraw, setIsDraw] = useState<boolean>(false);
	const [isDrawStep, setIsDrawStep] = useState<boolean>(false);
	const { pathname } = useLocation();

	useEffect(() => {
	  if (pathname.includes('project/create')) {
			setIsDraw(isDrawStep);
	  } else {
			setIsDraw(false);
	  }
	//   eslint-disable-next-line
	}, [pathname, isDrawStep])
	
	const contextValue = useMemo(
		() => ({
			drawStyle,
			setDrawStyle,
			isDraw,
			setIsDraw,
			setIsDrawStep,
		}),
		// eslint-disable-next-line
		[drawStyle, setDrawStyle, isDraw, setIsDrawStep],
	);

	return (
		<MapDrawContext.Provider value={contextValue}>
			{children}
		</MapDrawContext.Provider>
	);
};
