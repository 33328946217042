import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FilterContext, initialComplexFilter, initialSimpleFilter } from './context';
import { IComplexFilter, IFiltersContextType, ISimpleFilter } from './types';

export const FiltersProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const { pathname } = useLocation();

	const [complexFilter, setComplexFilter] = useState<IComplexFilter>(initialComplexFilter);
	const [simpleFilter, setSimpleFilter] = useState<ISimpleFilter>(initialSimpleFilter);
	const [applyFilter, setApplyFilter] = useState<() => void>(() => {});
	const [clearFilter, setClearFilter] = useState<() => void>(() => {});

	const resetContext = (): void => {
		setComplexFilter(initialComplexFilter);
		setSimpleFilter(initialSimpleFilter);
		setApplyFilter(() => {});
		setClearFilter(() => {});
	};

	useEffect(() => {
		if(pathname){
			resetContext();
		}
	}, [pathname]);
    
	const contextValue: IFiltersContextType = useMemo(() => ({
		complexFilter,
		setApplyFilter,
		setClearFilter,
		setComplexFilter,
		setSimpleFilter,
		simpleFilter,
		applyFilter,
		clearFilter,
		// eslint-disable-next-line
	}), [complexFilter, simpleFilter, applyFilter, clearFilter]);

	return (
		<FilterContext.Provider value={contextValue}>
			{children}
		</FilterContext.Provider>
	);
};