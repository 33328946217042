import { useCallback, useEffect, useState } from 'react';
import { fetchQuerySubscriptions, fetchSubscriptions } from './actions';
import { UserSubscriptionsReturnType } from './types';
import { notify, stateSetter } from '@/utils';
import { ISubscribedProjects } from '../use-app-users/types';

export function useUserSubscriptions<T extends ISubscribedProjects | ISubscribedProjects[]>(
	userId: string | undefined = undefined,
	queryParam: string | undefined = undefined
): UserSubscriptionsReturnType<T> {
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [enableQuery, setEnableQuery] = useState<boolean>(false);
	const [page, setPage] = useState<number>(0);
	const [items, setItems] = useState<number>(5);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [totalItems, setTotalItems] = useState<number>(1);

	const handleResponse = (pageParam: number, itemsParam: number): void => {
		setTotalPages(pageParam);
		setTotalItems(itemsParam);
	};

	const getSubscriptions = useCallback(
		async (id: string, pageParam: number, itemsParam: number) => {
			try {
				setLoading(true);
				const response = await fetchSubscriptions(id, pageParam, itemsParam, handleResponse);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
        [setData]
	);

	const getQuerySubscriptions = useCallback(
		async (id: string, query: string, queryPage: number, queryItems: number) => {
			try {
				!enableQuery && setEnableQuery(true);
				setLoading(true);
				const response = await fetchQuerySubscriptions(id, query, queryPage, queryItems, handleResponse);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
        [setData]
	);

	useEffect(() => {
		if (userId && queryParam === undefined) {
			getSubscriptions(userId, page, items);
		}
		// eslint-disable-next-line
    }, [userId, queryParam, page, items]);

	useEffect(() => {
		if (queryParam && userId) {
			getQuerySubscriptions(userId, queryParam, page, items);
		} else if (enableQuery && userId) {
			getSubscriptions(userId, page, items);
		}
		// eslint-disable-next-line
    }, [queryParam, userId, page, items]);

	return {
		data: data || ([] as ISubscribedProjects[] as T),
		setData: setData as stateSetter<T>,
		getSubscriptions,
		loading,
		items,
		page,
		setItems,
		setPage,
		totalPages,
		totalItems
	};
}