import { FC, Suspense, useEffect } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import {
	AuthProvider, ErrorBoundary, NotificationsProvider, StoreProvider,
	useSocketContext
} from './providers';
import { RoutesContainer } from './routes';
import { FiltersProvider } from './providers/filters-provider/provider';
import { Flex } from './components';

const LoadingFallback: FC = () => <Flex width={'100%'} justifyCenter>
	<CircularProgress />
</Flex>;

const App: FC = () => {
	const { disconnectSocket } = useSocketContext();

	useEffect(() =>
		// eslint-disable-next-line
		() => {
			disconnectSocket();
		}
		// eslint-disable-next-line
		, []);

	return (
		<Suspense fallback={<LoadingFallback />}>
			<ErrorBoundary>
				<StoreProvider>
					<AuthProvider>
						<NotificationsProvider />
						<BrowserRouter>
							<FiltersProvider>
								<RoutesContainer />
							</FiltersProvider>
						</BrowserRouter>
					</AuthProvider>
				</StoreProvider>
			</ErrorBoundary>
		</Suspense>
	);
};

export default App;
