import { CloudDone } from '@mui/icons-material';
import {
	TableBody,
	TableCell,
	TableRow,
	Typography
} from '@mui/material';
import { FC, memo } from 'react';
import { ListComponent } from '../../../components/data-map';
import { CustomTableCell, CustomTableRow } from '../styled';
import { TableBodyFallback } from './fallback';
import { ICustomTableBodyProps } from './types';
import { TableText } from '@/components/table-text-component';

export const CustomTableBody: FC<ICustomTableBodyProps> = ({
	tableId,
	columns,
	rows,
	loading,
	isNarrow,
}) => {
	if (loading) {
		return <TableBodyFallback tableId={tableId} columns={columns} isNarrow={isNarrow} />;
	}

	if (!rows?.length) {
		return (
			<TableBody>
				<TableRow>
					<TableCell
						colSpan={columns?.length}
						align="center"
						sx={{ background: '#fff', height: '300px' }}
					>
						<CloudDone fontSize="large" color="primary" />
						<Typography variant="body1">
							{'Nu sunt date disponibile'}
						</Typography>
					</TableCell>
				</TableRow>
			</TableBody>
		);
	}

	return (
		<TableBody>
			<ListComponent
				items={rows}
				renderItem={(row, _i) => (
					<CustomTableRow isNarrow={isNarrow}>
						<ListComponent
							items={columns}
							renderItem={(column, _index) =>
								(
									<CustomTableCell sx={{ width: column.width }} align={column.align}>
										{
											typeof ((row as object)[column.key as keyof typeof row]) === 'string' ?
												<TableText text={(row as object)[column.key as keyof typeof row]} /> :
												(row as object)[column.key as keyof typeof row]
										}
									</CustomTableCell>
								)
							}
						/>
					</CustomTableRow>
				)}
			/>
		</TableBody>
	);
};

export const MemoizedTableBody = memo(CustomTableBody);
