import { FC, ReactNode } from 'react';
import { SimpleDrawerHeader } from './header';
import { BaseDrawer, DrawerContent } from './styled';

interface ISimpleDrawerProps {
    title: string;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    children?: ReactNode;
}

export const SimpleDrawer: FC<ISimpleDrawerProps> = ({ title, open, setOpen, children}) => {
	const closeDrawer = (): void => {
		setOpen((prev: boolean) => !prev);
	};
	return (
		<BaseDrawer variant="permanent" open={open}>
			<SimpleDrawerHeader title={title} open={open} closeDrawer={closeDrawer} />
			<DrawerContent>
				{children}
			</DrawerContent>
		</BaseDrawer>
	);
};

SimpleDrawer.defaultProps = {
	children: undefined
};