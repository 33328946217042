import { FC, ReactNode } from 'react';
import { SimpleDrawer } from '../simple-drawer';
import { LayoutBase } from './styled';

interface IDrawerSideContentProps {
    siderChildren?: ReactNode;
    children?: ReactNode;
    drawerTitle: string;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DrawerSideContent: FC<IDrawerSideContentProps> = ({
	drawerTitle, 
	children, 
	siderChildren, 
	open, 
	setOpen
}) => (
	<>
		<SimpleDrawer title={drawerTitle} open={open} setOpen={setOpen}>
			{siderChildren}
		</SimpleDrawer>
		<LayoutBase isDrawerOpen={open}>
			{children}
		</LayoutBase>
	</>
);

DrawerSideContent.defaultProps = {
	children: undefined,
	siderChildren: undefined
};