import { AxiosError } from 'axios';
import { edit } from '@/utils';
import { IRestrictObj } from './types';

async function patchMute(userId: string, restrictObj: IRestrictObj): Promise<string | number> {
	try {
		const res = await edit(`api/protected/users/${userId}/mute`, {...restrictObj});
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa restrictionam utilizatorul.');
	}
}

async function patchMuteChat(userId: string, restrictObj: IRestrictObj): 
	Promise<{messages: string[], status: number} | number> {
	try {
		const res = await edit(`api/protected/users/${userId}/mute`, {...restrictObj});
		const data = res?.data as unknown as { messages: string[]};
		return res?.status === 200 ? {messages: data?.messages, status: res?.data?.status} : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu ma reusit sa restrictionam utilizatorul.');
	}
}

async function patchBlock(userId: string, restrictObj: IRestrictObj): Promise<string | number> {
	try {
		const res = await edit(`api/protected/users/${userId}/block`, {...restrictObj});
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa blocam utilizatorul.');
	}
}

async function patchBlockChat(userId: string, restrictObj: IRestrictObj): 
	Promise<{messages: string[], status: number} | number> {
	try {
		const res = await edit(`api/protected/users/${userId}/block`, {...restrictObj});
		const data = res?.data as unknown as { messages: string[]};
		return res?.status === 200 ? {messages: data?.messages, status: res?.data?.status} : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu ma reusit sa blocam utilizatorul.');
	}
}

async function patchUnblock(userId: string): Promise<string | number> {
	try {
		const res = await edit(`api/protected/users/${userId}/unblock`);
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa deblocam utilizatorul.');
	}
}

async function patchUnmute(userId: string): Promise<string | number> {
	try {
		const res = await edit(`api/protected/users/${userId}/unmute`);
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa derestrictionam utilizatorul.');
	}
}

export { patchBlock, patchMute, patchUnblock, patchUnmute, patchBlockChat, patchMuteChat };

