import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { IAnnouncementsContext } from './types';
import { AnnouncementsContext } from './context';
import { IProject, useAnnouncements, useProjects } from '@/hooks';
import { IAnnouncement } from '@/hooks/fetch-hooks/use-announcments/types';
import { generateQueryString, notify, useAppDispatch, useAppSelector } from '@/utils';
import { projectActions } from '@/store/project/slice';
import { useFiltersContext } from '../filters-provider/context';

export const AnnouncementsProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const dispatch = useAppDispatch();
	const { complexFilter, setApplyFilter, setClearFilter } = useFiltersContext();
	const { announcementProject: projId } = useAppSelector((state) => state.project);

	const [queryAnn, setQueryAnn] = useState<string | undefined>();
	const [queryProj, setQueryProj] = useState<string | undefined>();
	const { data: projects, loading: loadingProject } = useProjects<IProject[]>(
		true, undefined, queryProj, true, true
	);
	const [selectedProject, setSelectedProject] = useState<IProject | null>(null);
	const {
		data: announcements,
		setData: setAnnouncements,
		loading: loadingAnnouncements,
		removeAnnouncement,
		loadingCTA,
		editAnnouncement,
		items,
		setItems,
		page,
		setPage,
		totalPages,
		totalItems
	} = useAnnouncements<IAnnouncement[]>(projId, undefined, queryAnn);

	useEffect(() => {
		// eslint-disable-next-line
		setApplyFilter(() => () => setQueryProj(
			() => generateQueryString({
				search: complexFilter?.name,
				status: complexFilter?.status,
				stage: complexFilter?.stage,
				uat: complexFilter?.uat,
				county: complexFilter?.county,
				institution: complexFilter?.fsp
			})
		));
		// eslint-disable-next-line
		setClearFilter(() => () => setQueryProj(undefined));
		// eslint-disable-next-line
	}, [complexFilter]);

	const handleSelectProject = (project: IProject | null): void => {
		setSelectedProject(project);
		project && dispatch(projectActions.setAnnouncementProject(project?._id));
	};

	const removeSuccess = (): void => {
		notify.success('Anunț șters cu succes');
	};

	const handleRemove = (id: string): void => {
		removeAnnouncement(id, removeSuccess);
	};

	const getAnnouncementsQuery = (params: Record<string, any>): void => {
		const query = generateQueryString(params);
		setQueryAnn(query);
	};

	useEffect(() => {
		if (projects && projId) {
			const temp = projects.find(el => el._id === projId);
			temp && setSelectedProject(temp);
			setQueryAnn(undefined);
			setPage(0);
			setItems(5);
		}
		//   eslint-disable-next-line
	}, [projects, projId])

	const value: IAnnouncementsContext = useMemo(() => ({
		projects,
		loadingProject,
		selectedProject,
		setSelectedProject,
		loadingAnnouncements,
		projectAnnouncements: announcements,
		handleSelectProject,
		loadingCTA,
		handleRemove,
		getAnnouncementsQuery,
		editAnnouncement,
		setAnnouncements,
		setQueryAnn,
		items,
		setItems,
		page,
		setPage,
		totalPages,
		totalItems
		// eslint-disable-next-line
	}), [
		projects, loadingProject, selectedProject, loadingAnnouncements,
		announcements, loadingCTA, items, page, totalPages, totalItems
	]);

	return <AnnouncementsContext.Provider value={value}>{children}</AnnouncementsContext.Provider>;
};