import { Box, BoxProps, styled } from '@mui/material';
import { FC } from 'react';

interface IToggleButtonContainerProps extends BoxProps {
    isHovered: boolean;
}

export const ToggleButtonContainer = styled(Box as FC<IToggleButtonContainerProps>, {
	shouldForwardProp: (prop: string) => !['isHovered'].includes(prop),
})(({ theme, isHovered }) => ({
	position: 'absolute',
	top: '50%',
	right: -16,
	border: `1px solid ${theme.palette.primary.main}`,
	backgroundColor: theme.palette.common.white,
	width: '30px',
	height: '30px',
	borderRadius: '50%',
	display:'flex',
	justifyContent: 'center',
	alignItems: 'center',
	transform: 'translate(0, -50%)',
	opacity: isHovered ? 1 : 0,
	transition: 'all 0.2s ease-in-out',
	'.MuiSvgIcon-root': {
		color: '#000!important'
	},
	cursor: 'pointer'
}));