import { 
	Badge, BadgeProps, Box, BoxProps, Button, ButtonProps, 
	Card, CardProps, styled, Typography, TypographyProps 
} from '@mui/material';
import { FC } from 'react';

export interface IHeadreProps extends CardProps {
	isDrawerOpen: boolean;
	headerSize?: number;
}

const exclude =['isDrawerOpen', 'headerSize'];

export const HeaderContainer: FC<IHeadreProps> = styled(Card as FC<IHeadreProps>,{
	shouldForwardProp: (prop: string) => !exclude.includes(prop),
})(({ theme, isDrawerOpen, headerSize }) => ({
	width: '100%',
	maxWidth: `calc(100% - ${isDrawerOpen ? 266 : 144}px)`,
	minHeight: '56px',
	height: headerSize || 56,
	display: 'flex',
	flexDirection: 'column',
	position: 'absolute',
	backgroundColor: theme.palette.common.white,
	top: 32,
	left: isDrawerOpen ? 250 : 128,
	borderRadius: '16px',
	padding: '0 16px',
	boxShadow: theme.shadows[5],
	transition: 'all 0.2s ease-in-out'
}));

export const HeaderRightSide: FC<BoxProps> = styled(Box as FC<BoxProps>)(() => ({
	height: '56',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'felx-end',
	gap: 16
}));

export const StyledBadge = styled(Badge as FC<BadgeProps>)(({ theme }) => ({
	'& .MuiBadge-badge': {
	  backgroundColor: theme.palette.success.light,
	  color: theme.palette.success.light,
	  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
	  '&::after': {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			borderRadius: '50%',
			content: '""',
	  },
	},
}));

export const HeaderUserName = styled(Typography as FC<TypographyProps>)(({ theme }) => ({
	fontWeight: 600,
	fontSize: '13px',
	lineHeight: '22px',
	color: theme.palette.primary.main
}));

export const HeaderRoleButton = styled(Button as FC<ButtonProps>)(({ theme }) => ({
	fontWeight: 600,
	color: theme.palette.primary.dark
}));

export const HeaderFilterButton = styled(Button as FC<ButtonProps>)(({ theme }) => ({
	fontWeight: 600,
	color: theme.palette.primary.main
}));

interface IHeaderContentContainerProps extends BoxProps {
	hasFilters?: boolean;
}

export const HeaderContentContainer: FC<IHeaderContentContainerProps> = styled(Box as FC<IHeaderContentContainerProps>,{
	shouldForwardProp: (prop: string) => prop !== 'hasFilters'
})(({ hasFilters}) => ({
	width: '100%',
	height: '56px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: hasFilters ? 'space-between' : 'flex-end',
}));