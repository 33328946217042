export const capitalize = (str: string): string => `${str.at(0)?.toUpperCase()}${str.slice(1)}`;

export const minimize = (str: string): string => `${str.at(0)?.toLowerCase()}${str.slice(1)}`;

export const { isArray } = Array;

export function ifIsArray<T>(el: any): T[] {
	return (isArray(el) ? [...el] : []) as T[];
}

export { v4 as uuid } from 'uuid';

type NestedObject = Record<string, any>;

export function hasEmptyOrNull(obj: NestedObject): boolean {
	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			const value = obj[key];
			if (value === null || value === '') {
				return true;
			} if (typeof value === 'object' && value !== null) {
				// Recursive call for nested objects
				if (hasEmptyOrNull(value)) {
					return true;
				}
			}
		}
	}
	return false;
}

export function calculateAdvancedClusterDistance(
	zoom: number,
	featureDensity: number,
	viewportSize: { width: number; height: number },
): number {
	let distance = 40;
  
	if (zoom < 10) {
	  distance += 30;
	} else if (zoom < 15) {
	  distance += 20;
	} else if (zoom < 16) {
	  distance += 10;
	} else if (zoom < 17) {
	  distance += 5;
	} else {
	  distance = 1;
	}
  
	if (featureDensity > 100) {
	  distance *= 1.5;
	} else if (featureDensity < 50) {
	  distance *= 0.75;
	}
  
	const viewportArea = viewportSize.width * viewportSize.height;
	if (viewportArea > 800000) {
	  distance *= 0.8;
	}
  
	return Math.round(distance);
}
  
export function calculateAreaRatioThreshold(zoom: number): number {
	const maxZoom = 18;
	const minZoom = 10;
	const minThreshold = 0.05;
	const maxThreshold = 0.2;
  
	if (zoom >= maxZoom) {
	  return maxThreshold;
	} if (zoom <= minZoom) {
	  return minThreshold;
	} 
	  const scale = (zoom - minZoom) / (maxZoom - minZoom);
	  return minThreshold + scale * (maxThreshold - minThreshold);
	
}

// Function to generate query string
export const generateQueryString = (params: Record<string, any>): string => {
	const queryString = Object.keys(params)
	  .filter(key => params[key] !== undefined && params[key] !== null && params[key] !== '')
	  .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
	  .join('&');
	return queryString ? `?${queryString}` : '';
};