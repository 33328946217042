import { alpha, Button, ButtonProps, styled } from '@mui/material';
import { FC } from 'react';

interface IButtonBaseProps extends ButtonProps {
    isActive: boolean;
}

export const ButtonBase = styled(Button as FC<IButtonBaseProps>,{
	shouldForwardProp: (prop: string) => prop !== 'isActive'
})(({ theme, isActive }) => ({
	backgroundColor: isActive ? alpha(theme.palette.primary.light, 0.1) : 'inherit'
}));