import React from 'react';
import { IconButton, Box } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';

const CustomTablePaginationActions: React.FC<TablePaginationActionsProps> = ({
	count,
	page,
	rowsPerPage,
	onPageChange,
}) => {
	const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		onPageChange(event, page + 1);
	};

	const isLastPage = page >= Math.ceil(count / rowsPerPage) - 1;

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				<KeyboardArrowLeft />
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={isLastPage}
				aria-label="next page"
			>
				<KeyboardArrowRight />
			</IconButton>
		</Box>
	);
};

export default CustomTablePaginationActions;
