import { Skeleton, TableBody, TableCell, TableRow } from '@mui/material';
import { FC, memo } from 'react';
import { IColumn } from '../types';
import { uuid } from '@/utils';

const TableCellFallback: FC<{ isNarrow: boolean }> = ({ isNarrow }) => (
	<Skeleton animation="wave" variant="rectangular" width="100%" height={isNarrow ? 18 : 35} />
);

const TableRowFallback: FC<{
	tableId: string;
	columns: IColumn[];
	rowIndex: number;
	isNarrow: boolean;
}> = ({ tableId, columns, rowIndex, isNarrow }) => (
	<TableRow>
		{columns.map((col) => (
			<TableCell
				sx={{ width: col.width }}
				align={col.align}
				key={`table-body-fallback-cell-${tableId}-${uuid()}-${rowIndex}`}
			>
				<TableCellFallback isNarrow={isNarrow} />
			</TableCell>
		))}
	</TableRow>
);

export const TableBodyFallback: FC<{ tableId: string; columns: IColumn[], isNarrow: boolean }> =
	memo(({ tableId, columns, isNarrow }) => (
		<TableBody>
			{new Array(5).fill('').map((_, i) => (
				<TableRowFallback
					isNarrow={isNarrow}
					rowIndex={i}
					tableId={tableId}
					columns={columns}
					key={`table-body-fallback-row-${tableId}-${uuid()}`}
				/>
			))}
		</TableBody>
	));
