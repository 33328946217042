import { createContext, useContext } from 'react';
import { IAnnouncementsContext } from './types';

export const AnnouncementsContext = createContext<IAnnouncementsContext>({
	loadingProject: true,
	projects: null,
	selectedProject: null,
	loadingAnnouncements: true,
	projectAnnouncements: [],
	setSelectedProject: () => { },
	handleSelectProject: () => { },
	handleRemove: () => { },
	loadingCTA: false,
	getAnnouncementsQuery: () => { },
	setAnnouncements: () => { },
	setQueryAnn: () => { },
	items: 5,
	page: 0,
	setItems: () => { },
	setPage: () => { },
	totalPages: 1,
	totalItems: 1
});

export const useAnnouncementsContext = (): IAnnouncementsContext => useContext(AnnouncementsContext);