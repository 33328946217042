import { createContext, useContext } from 'react';
import { IComplexFilter, IFiltersContextType, ISimpleFilter } from './types';

export const initialComplexFilter: IComplexFilter = {
	county: '',
	fsp: '',
	name: '',
	stage: '',
	status: '',
	uat: '',
};

export const initialSimpleFilter: ISimpleFilter = {
	county: '',
	search: '',
	seen: null,
	status: '',
	uat: '',
};

export const FilterContext = createContext<IFiltersContextType>({
	complexFilter: initialComplexFilter,
	simpleFilter: initialSimpleFilter,
	setApplyFilter: () => {},
	setClearFilter: () => {},
	setComplexFilter: () => {},
	setSimpleFilter: () => {},
	applyFilter: () => {},
	clearFilter: () => {},
});

export const useFiltersContext = (): IFiltersContextType => {
	const context = useContext(FilterContext);
	if (!context) {
		throw new Error('useFiltersContext must be used within a FiltersProvider');
	}
	return context;
};