import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { IConsultation, IProject, useConsultations, useProjects } from '@/hooks';
import { projectActions } from '@/store/project/slice';
import { generateQueryString, notify, useAppDispatch, useAppSelector } from '@/utils';
import { ConsultationsContext } from './context';
import { IConsultationsContext } from './types';
import { useFiltersContext } from '../filters-provider/context';

export const ConsultationsProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const dispatch = useAppDispatch();
	const { complexFilter, setApplyFilter, setClearFilter } = useFiltersContext();
	const { consultationProject: projId } = useAppSelector((state) => state.project);

	const [queryProj, setQueryProj] = useState<string | undefined>();
	const [queryCons, setQueryCons] = useState<string | undefined>();
	const { data: projects, loading: loadingProject } = useProjects<IProject[]>(
		true, undefined, queryProj, true, true
	);
	const [selectedProject, setSelectedProject] = useState<IProject | null>(null);
	const {
		data,
		loading,
		editConsultation,
		loadingCTA,
		setData,
		removeConsultation,
		items,
		setItems,
		page,
		setPage,
		totalPages,
		totalItems
	} = useConsultations<IConsultation[]>(projId, undefined, queryCons);

	useEffect(() => {
		// eslint-disable-next-line
		setApplyFilter(() => () => setQueryProj(
			() => generateQueryString({
				search: complexFilter?.name,
				status: complexFilter?.status,
				stage: complexFilter?.stage,
				uat: complexFilter?.uat,
				county: complexFilter?.county,
				institution: complexFilter?.fsp
			})
		));
		// eslint-disable-next-line
		setClearFilter(() => () => setQueryProj(undefined));
		// eslint-disable-next-line
	}, [complexFilter]);

	const handleSelectProject = (project: IProject | null): void => {
		setSelectedProject(project);
		project && dispatch(projectActions.setConsultationProject(project?._id));
	};

	const getConsultationsQuery = (params: Record<string, any>): void => {
		const query = generateQueryString(params);
		setQueryCons(query);
	};
	const removeSuccess = (): void => {
		notify.success('Consultare ștearsă cu succes');
	};

	const handleRemove = (id: string): void => {
		removeConsultation(id, removeSuccess);
	};

	useEffect(() => {
		if (projects && projId) {
			const temp = projects.find(el => el._id === projId);
			temp && setSelectedProject(temp);
			setPage(0);
			setItems(5);
		}
		//   eslint-disable-next-line
	}, [projects, projId])

	const value: IConsultationsContext = useMemo(() => ({
		projects,
		loadingProject,
		selectedProject,
		setSelectedProject,
		handleSelectProject,
		consultation: data,
		loadingCTA,
		editConsultation,
		loadingConsultation: loading,
		setConsultation: setData,
		getConsultationsQuery,
		handleRemove,
		setQueryCons,
		items,
		setItems,
		page,
		setPage,
		totalPages,
		totalItems
		// eslint-disable-next-line
	}), [
		projects, loadingProject, selectedProject, loadingCTA, loading,
		items, page, totalPages, totalItems
	]);

	return <ConsultationsContext.Provider value={value}>{children}</ConsultationsContext.Provider>;
};