import { FC, Fragment, useMemo } from 'react';
import { utilsActions } from '@/store/utils/slice';
import { useAppDispatch, useAppSelector, uuid } from '@/utils';
import SuperadminMenu from './data/superadmin-menu.json';
import AdminMenu from './data/admin-menu.json';
import FspMenu from './data/fsp-menu.json';
import ModeratorMenu from './data/moderator-menu.json';
import { DrawerFooter } from './drawer-footer';
import { DrawerHeader } from './drawer-header';
import { MenuTabs } from './menu-items';
import { Drawer, MenuWrapper } from './styled';
import { useAuthContext, UserRole } from '@/providers';

export interface MenuItem {
  type?: string;
  title: string;
  icon: string;
  url: string;
  children?: MenuItem[];
}

// eslint-disable-next-line
const getMenuByUserRole = (role: UserRole) =>  {
	switch (role) {
		case 'superadmin':
			return SuperadminMenu;
		case 'admin':
			return AdminMenu;
		case 'moderator':
			return ModeratorMenu;
		case 'fsp':
			return FspMenu;
		default:
			return undefined;
	};
};

export const SideDrawer: FC = () => {
	const { isDrawerOpen } = useAppSelector((state) => state.utils);
	const { user } = useAuthContext();
	const dispatch = useAppDispatch();

	const menuItems = useMemo(() => getMenuByUserRole(user?.role), [user?.role]);

	return (
		<Drawer variant="permanent" open={isDrawerOpen} >
			<DrawerHeader
				isHovered
				open={isDrawerOpen}
				closeDrawer={(): { payload: undefined; type: 'utils/toggleDrawer' } =>
					dispatch(utilsActions.toggleDrawer(!isDrawerOpen))
				}
			/>
			{/* ****************| MENU ACTIONS |************************* */}
			<MenuWrapper>
				{ menuItems && menuItems.map(
					(el) =>
						!!el.children?.length && (
							<Fragment key={`menu-tabs-${uuid()}`}>
								<MenuTabs menuItems={el?.children || []} />
							</Fragment>
						),
				)}
			</MenuWrapper>
			<DrawerFooter isOpen={isDrawerOpen} />
		</Drawer>
	);
};
