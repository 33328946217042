import { Reducer, Tuple, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer } from 'redux-persist';
import logger from 'redux-logger';
import { Middlewares } from '@reduxjs/toolkit/dist/configureStore';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import { rootReducer } from './root-reducer';
import { STORE_KEY } from './constants';
import { reducers } from '@/store';

const persistConfig = {
	key: STORE_KEY,
	storage,
	whitelist: ['utils', 'project', 'survey'],
};

type TransformedReducer = Reducer<{
  [K in keyof typeof reducers]: (typeof reducers)[K] extends Reducer<infer U> ? U : never;
}>;

const reducer = persistReducer(persistConfig, rootReducer as unknown as TransformedReducer);

export const store = configureStore({
	reducer,
	middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
    	serializableCheck: {
    		ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    	},
    	// eslint-disable-next-line @typescript-eslint/ban-types
    }).concat(logger) as Tuple<Middlewares<{} & PersistPartial>>,
});

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;

setupListeners(store.dispatch);
