import { Box, TablePagination, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';
import { ICustomTablePaginationProps } from './types';

export const CustomTablePagination: FC<ICustomTablePaginationProps> = ({
	totalRows,
	page,
	handleChangePage,
	rowsPerPage,
	handleChangeRowsPerPage,
	customComponent,
}): JSX.Element => {
	const theme = useTheme();
	const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

	// Function to determine rowsPerPageOptions with explicit return type
	const getRowsPerPageOptions = (): number[] => {
		if (matchDownSM && totalRows < 5) {
			return [totalRows];
		}
		if (totalRows >= 5) {
			return [5];
		}
		if (totalRows <= 10) {
			return [5, totalRows];
		}
		return [5, 10, totalRows];
	};

	if (customComponent) {
		return <Box sx={{ p: 0, m: 0, width: '100%' }}>{customComponent}</Box>;
	}

	return (
		<TablePagination
			component="div"
			labelRowsPerPage={'Rânduri pe pagină:'}
			rowsPerPage={rowsPerPage}
			rowsPerPageOptions={getRowsPerPageOptions()}
			count={totalRows}
			page={page}
			onPageChange={handleChangePage}
			onRowsPerPageChange={handleChangeRowsPerPage}
			sx={{
				backgroundColor: '#fff',
				width: '100%',
				color: '#000',
			}}
		/>
	);
};
