import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { Clear, Save } from '@mui/icons-material';
import { Flex } from '../flex';
import { useFileUploader } from '@/hooks';
import { notify, stateSetter } from '@/utils';

async function blobUrlToFile(blobUrl: string, fileName: string): Promise<File> {
	const response = await fetch(blobUrl);
	const blob = await response.blob();
	return new File([blob], fileName, { type: blob.type });
}

interface IAddCoverComponent {
    cloudHandler: stateSetter<string>;
}

// eslint-disable-next-line
const placeholder = 'https://storage.googleapis.com/cityzen-bucket/1722676016650-selectedCover.jpg'

export const AddCoverComponent: FC<IAddCoverComponent> = ({ cloudHandler }) => {
	const fileInputRef = useRef<HTMLInputElement>(null);
	const [image, setImage] = useState<string>(placeholder);
	const [cloudImg, setCloudImg] = useState<string>('');
	const { addFile, removeFile, loading} = useFileUploader();

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const fileList = event.target.files;
		if (fileList && fileList.length > 0) {
			const file = fileList[0];
			const fileUrl = URL.createObjectURL(file);
			setImage(fileUrl);
		}
		if (fileInputRef.current) {
			fileInputRef.current.value = '';
		}
	};

	const handleButtonClick = useCallback(() => {
		fileInputRef.current?.click();
	}, []);

	const handleRemoveImage = (file: string): void => {
		removeFile(file, () => {
			setCloudImg('');
			setImage('');
			notify.info('Imagine ștearsă');
		});
	};

	const handleClear = (): void => {
		if(cloudImg){
			handleRemoveImage(cloudImg);
		} else {
			setImage('');
		}
	};

	const handleSave = async (): Promise<void> => {
		const myFile = await blobUrlToFile(image, 'selectedCover.jpg');
		addFile(myFile, (fileUrl) => {
			setCloudImg(fileUrl);
			notify.info('Imagine adăugată');
		});
	};

	useEffect(() => {
		if(cloudImg?.length > 0){
			cloudHandler(cloudImg);
		} else {
			cloudHandler('');
		}
		// eslint-disable-next-line
	}, [cloudImg]);

	return (
		<Flex width={'100%'} column sx={{ position: 'relative'}} >
			<Flex width={'100%'} justifyCenter gap={2} sx={{position: 'relative'}} >
				{cloudImg?.length === 0 && image?.length > 0 && image !== placeholder &&
				<Tooltip title='Șterge avatar'>
					<IconButton onClick={handleClear}>
						<Clear />
					</IconButton>
				</Tooltip>
				}
				<Box component={'img'} src={image || placeholder} alt={'cover-component'} sx={{
					width: '400px', height: '120px', border: '1px solid #9e9e9e', borderRadius: '8px',
					objectFit: 'cover'
				}} />
				{cloudImg?.length === 0 && image?.length > 0 && image !== placeholder &&
				<Tooltip title='Salvează avatar'>
					<IconButton onClick={handleSave}>
						<Save />
					</IconButton>
				</Tooltip>
				}
				{loading &&
				<Box sx={{ position: 'absolute'}}>
					<CircularProgress />
				</Box>
				}
			</Flex>
			<Box sx={{ position: 'absolute', top: -60}}>
				<input
					type="file"
					accept="image/*"
					onChange={handleFileChange}
					ref={fileInputRef}
					style={{ display: 'none' }}
				/>
				<Button variant='text' onClick={cloudImg?.length > 0 ? handleClear : handleButtonClick} 
					sx={{ my: 2}}>
					{/* eslint-disable-next-line */}
					{cloudImg?.length === 0 ? image?.length > 0 && image !== placeholder ? 
						'Schimbă poza de fundal' : '+ Poză de fundal ' : ''
					}
					{cloudImg?.length > 0 && 'Șterge poza de fundal'}
				</Button>
			</Box>
		</Flex>
	);
};