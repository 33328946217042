import { useCallback, useEffect, useState } from 'react';
import { deleteRequest, fetchQueryRequests, fetchRequests, fetchSingleRequest, patchRequest } from './actions';
import { IRequest, RequestsReturnType } from './types';
import { notify, stateSetter } from '@/utils';

export function useRequests<T extends IRequest | IRequest[]>(
	getAppRequestsOnRender: boolean | undefined = undefined,
	scope: 'moderator' | 'fsp' | undefined = undefined,
	requestId: string | undefined = undefined,
	queryParam: string | undefined = undefined
): RequestsReturnType<T> {
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [loadingCTA, setLoadingCTA] = useState<boolean>(false);
	const [enableQuery, setEnableQuery] = useState<boolean>(false);
	const [page, setPage] = useState<number>(0);
	const [items, setItems] = useState<number>(5);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [totalItems, setTotalItems] = useState<number>(1);

	const handleResponse = (pageParam: number, itemsParam: number): void => {
		setTotalPages(pageParam);
		setTotalItems(itemsParam);
	};

	const getRequests = useCallback(
		async (requestScope: 'moderator' | 'fsp', pageParam: number, itemsParam: number) => {
			try {
				setLoading(true);
				const response = await fetchRequests(requestScope, pageParam, itemsParam, handleResponse);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getQueryRequests = useCallback(
		async (query: string, queryPage: number, queryItems: number) => {
			try {
				!enableQuery && setEnableQuery(true);
				setLoading(true);
				const response = await fetchQueryRequests(query, queryPage, queryItems, handleResponse);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getRequest = useCallback(
		async (reqId: string) => {
			try {
				setLoading(true);
				const response = await fetchSingleRequest(reqId);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const removeRequest = useCallback(
		async (reqId: string, onSuccess?: () => void) => {
			try {
				setLoadingCTA(true);
				const response = await deleteRequest(reqId);
				if (response === 200) {
					const temp = Array.isArray(data) ? data.map(request =>
						request._id === reqId ? { ...request, deleted: true } : request
					) : data;
					setData(temp as T);
					onSuccess && onSuccess();
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingCTA(false);
			}
		},
		// eslint-disable-next-line
		[setData, data]
	);

	const editRequest = useCallback(
		async (reqId: string, status: 'approved' | 'denied', onSuccess?: () => void) => {
			try {
				setLoadingCTA(true);
				const response = await patchRequest(reqId, status);
				if (response === 200) {
					setData((prev) => ({
						...prev,
						status
					} as T));
					onSuccess && onSuccess();
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingCTA(false);
			}
		},
		// eslint-disable-next-line
		[setData, data]
	);

	useEffect(() => {
		if (getAppRequestsOnRender && scope && queryParam === undefined) {
			getRequests(scope, page, items);
		}
		// eslint-disable-next-line
	}, [getAppRequestsOnRender, scope, page, items]);

	useEffect(() => {
		if (requestId) {
			getRequest(requestId);
		}
		// eslint-disable-next-line
	}, [requestId]);

	useEffect(() => {
		if (queryParam && requestId === undefined && scope) {
			getQueryRequests(queryParam, page, items);
		} else if (requestId === undefined && enableQuery && scope) {
			getRequests(scope, page, items);
		}
		// eslint-disable-next-line
	}, [queryParam, requestId, scope, page, items]);

	return {
		data: data || ([] as IRequest[] as T),
		setData: setData as stateSetter<T>,
		loading,
		getRequest,
		getRequests,
		removeRequest,
		editRequest,
		loadingCTA,
		items,
		page,
		setItems,
		setPage,
		totalItems,
		totalPages
	};
}