import axios, { CancelTokenSource } from 'axios';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { notify } from '@/utils';

interface IBoundingBox {
	top: number;
	left: number;
	bottom: number;
	right: number;
}

interface IGeoJsonFeatureCollection {
	type: 'FeatureCollection';
	features: any[];
}

// const transformToGeoJsonObject = (
//   data: any[],
//   layerId: string,
// ): IGeoJsonFeatureCollection => ({
//   type: 'FeatureCollection',
//   features: data.map((item) => ({
//     type: 'Feature',
//     properties: {
//       kind: 'county',
//       name: `${item.dataId}___${layerId}`,
//       state: 'BR',
//     },
//     geometry: item.geometry,
//   })),
// });

export const useLayers = (
	boundingBox: IBoundingBox,
	categoriesId: string[] = ['66138fbf8fca83eda8d76fdb'],
): {
	isLoading: boolean;
	dataMap: IGeoJsonFeatureCollection[];
	layerStyle: any;
} => {
	const [isLoading, setLoading] = useState<boolean>(true);
	const [dataMap, setDataMap] = useState<IGeoJsonFeatureCollection[]>([]);
	const [layerStyle, setLayerStyle] = useState<any>([]);
	const cancelTokenRef = useRef<CancelTokenSource | null>(null);

	const fetchDataMap = useCallback(async () => {
		if (cancelTokenRef.current) {
			cancelTokenRef.current.cancel();
		}

		cancelTokenRef.current = axios.CancelToken.source();

		setLoading(true);
		try {
			const { top, left, bottom, right } = boundingBox;
			const categoiesIdParams =
				categoriesId.join(',') || '66138fbf8fca83eda8d76fdb';
			// eslint-disable-next-line
			const endpoint = `api/map?categories=${categoiesIdParams}&top=${top}&left=${left}&bottom=${bottom}&right=${right}`;
			// const res = await get(endpoint, cancelTokenRef.current.token);
			const res = {} as any;

			if (!res.data || !res.data.categories) {
				setDataMap([]);
				// notify.error('No data found.');
				return;
			}

			const categories = await res?.data?.categories;

			const layersOfCategories: any = await categories?.map((category: any) => category?.layers);

			const vectors: IGeoJsonFeatureCollection[] =
				await layersOfCategories?.flatMap((layers: any) => layers.flatMap((layer: any) => {
					setLayerStyle([...layer.styles]);
					return layer;
				}));

			setDataMap(vectors);
			notify.success('Data Map loaded successfully');
		} catch (error) {
			if (axios.isCancel(error)) {
				console.log('Request canceled:', error.message);
			} else {
				console.error('Failed to load data map:', error);
				notify.error('Failed to load data map');
			}
		} finally {
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(categoriesId), JSON.stringify(boundingBox)]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedFetchDataMap = useCallback(debounce(fetchDataMap, 250), [
		fetchDataMap,
	]);

	useEffect(() => {
		debouncedFetchDataMap();
		// eslint-disable-next-line
		return () => {
			debouncedFetchDataMap.cancel();
			if (cancelTokenRef.current) {
				cancelTokenRef.current.cancel(
					'Component unmounted or parameters changed',
				);
			}
		};
	}, [debouncedFetchDataMap]);

	return { isLoading, dataMap, layerStyle };
};
