import React, { useState, useMemo } from 'react';
import { MapLayersContextType } from './types';
import { MapLayersContext } from './context';

export const MapLayersProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [zoom, setZoom] = useState<number>(7.2);
	const [center, setCenter] = useState<[number, number]>([2831408, 5796201]);
	const [boundingBox, setBoundingBox] = useState<
    MapLayersContextType['boundingBox']
  >({
  	top: 49,
  	left: 19,
  	bottom: 43,
  	right: 31.5,
  });

	const contextValue = useMemo(
		() => ({
			zoom,
			setZoom,
			center,
			setCenter,
			boundingBox,
			setBoundingBox,
		}),
		[zoom, center, boundingBox],
	);

	return (
		<MapLayersContext.Provider value={contextValue}>
			{children}
		</MapLayersContext.Provider>
	);
};
