import { AxiosError } from 'axios';
import { del, edit, get } from '@/utils';
import { IRequest } from './types';

async function fetchRequests(
	scope: 'moderator' | 'fsp',
	page: number, items: number,
	onResponse?: (page: number, items: number) => void
): Promise<IRequest[]> {
	try {
		const res = await get(`api/protected/requests?scope=${scope}&page=${page + 1}&items=${items}`);
		onResponse && onResponse(res.data?.pages, res?.data?.totalItems);
		const data = res.data as unknown as { requests: IRequest[] };
		return data?.requests || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm cererile.');
	}
}

async function fetchQueryRequests(
	query: string, page: number, items: number,
	onResponse?: (page: number, items: number) => void
): Promise<IRequest[]> {
	try {
		const res = await get(`api/protected/requests${query}&page=${page + 1}&items=${items}`);
		onResponse && onResponse(res.data?.pages, res?.data?.totalItems);
		const data = res.data as unknown as { requests: IRequest[] };
		return data?.requests || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm cererile.');
	}
}

async function fetchSingleRequest(requestId: string): Promise<IRequest> {
	try {
		const res = await get(`api/protected/requests/${requestId}`);
		const data = res.data as unknown as { request: IRequest };
		return data?.request || {};
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm cererea.');
	}
}

async function deleteRequest(requestId: string): Promise<string | number> {
	try {
		const res = await del(`api/protected/requests/${requestId}`);
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să ștergem cererea.');
	}
}

async function patchRequest(requestId: string, status: 'approved' | 'denied'): Promise<string | number> {
	try {
		const res = await edit(`api/protected/requests/${requestId}`, { status });
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să actualizăm cererea.');
	}
}

export { fetchRequests, fetchSingleRequest, deleteRequest, patchRequest, fetchQueryRequests };