import { AxiosError } from 'axios';
import { del, get, post, edit } from '@/utils';
import { IAdmins, ICreateAdmin, IUpdateAdmin } from './types';

async function fetchAdmins(
	page: number, items: number,
	onResponse?: (page: number, items: number) => void
): Promise<IAdmins[]> {
	try {
		const res = await get(`api/protected/admins?page=${page + 1}&items=${items}`);
		onResponse && onResponse(res.data?.pages, res?.data?.totalItems);
		const data = res.data as unknown as { admins: IAdmins[] };
		return data?.admins || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm datele.');
	}
}

async function fetchQueryAdmins(
	query: string, page: number, items: number,
	onResponse?: (page: number, items: number) => void
): Promise<IAdmins[]> {
	try {
		const res = await get(`api/protected/admins${query}&page=${page + 1}&items=${items}`);
		onResponse && onResponse(res.data?.pages, res?.data?.totalItems);
		const data = res.data as unknown as { admins: IAdmins[] };
		return data?.admins || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm datele.');
	}
}

async function fetchSingleAdmin(adminId: string): Promise<IAdmins> {
	try {
		const res = await get(`api/protected/admins/${adminId}`);
		const data = res.data as unknown as { admin: IAdmins };
		return data?.admin || {};
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm administratorul.');
	}
}

async function deleteAdmin(adminId: string): Promise<string | number> {
	try {
		const res = await del(`api/protected/admins/${adminId}`);
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să ștergem administratorul.');
	}
}

async function postAdmin(adminObject: ICreateAdmin): Promise<string | number> {
	try {
		const res = await post('api/protected/admins', { ...adminObject });
		return res?.status === 201 ? 201 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să adăugăm administratorul.');
	}
}

async function patchAdmin(adminId: string, updatedAdmin: IUpdateAdmin): Promise<string | number> {
	try {
		const res = await edit(`api/protected/admins/${adminId}`, { ...updatedAdmin });
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să actualizăm administratorul.');
	}
}

export { fetchAdmins, fetchSingleAdmin, deleteAdmin, postAdmin, patchAdmin, fetchQueryAdmins };
