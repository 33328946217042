import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React, { FC, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { SimpleDialogTransition } from '../simple-dialog-transition';
import { Flex } from '../flex';
import { DateRange, IDateRangePickerProps, Shortcut } from './types';

dayjs.locale('ro');

const shortcuts: Shortcut[] = [
	{ label: 'Această săptămână', type: 'thisWeek' },
	{ label: 'Săptămâna trecută', type: 'lastWeek' },
	{ label: 'Această lună', type: 'thisMonth' },
	{ label: 'Luna trecută', type: 'lastMonth' },
	{ label: 'Ultimele 3 luni', type: 'last3Months' },
	{ label: 'Anul acesta', type: 'thisYear' },
];

export const DateRangePicker: FC<IDateRangePickerProps> = ({
	inputLabel,
	endDate,
	setFilters,
	startDate
}) => {
	const [dateRange, setDateRange] = useState<DateRange>({
		start: dayjs(),
		end: dayjs().add(1, 'day'),
	});
	const [selectedShortcut, setSelectedShortcut] = useState<string | null>(null);
	const [openPicker, setOpenPicker] = useState(false);

	const handleShortcut = (type: Shortcut['type']): void => {
		const now = dayjs();
		const today = now.startOf('day');

		setSelectedShortcut(type);

		switch (type) {
			case 'thisWeek':
				setDateRange({
					start: today.isSame(now.startOf('week'), 'day')
						? today
						: now.startOf('week'),
					end: now.endOf('week'),
				});
				break;

			case 'lastWeek':
				setDateRange({
					start: now.subtract(1, 'week').startOf('week'),
					end: now.subtract(1, 'week').endOf('week'),
				});
				break;

			case 'thisMonth':
				setDateRange({
					start: today.isSame(now.startOf('month'), 'day')
						? today
						: now.startOf('month'),
					end: now.endOf('month'),
				});
				break;
			case 'lastMonth':
				setDateRange({
					start: now.subtract(1, 'month').startOf('month'),
					end: now.subtract(1, 'month').endOf('month'),
				});
				break;
			case 'last3Months':
				setDateRange({
					start: now.subtract(3, 'month').startOf('day'),
					end: today,
				});
				break;
			case 'thisYear':
				setDateRange({
					start: now.startOf('year'),
					end: now.endOf('year'),
				});
				break;
			default:
				break;
		}
	};

	const handleSave = (): void => {
		setFilters((props) => ({
			...props,
			startDate: dayjs(dateRange?.start)?.format('YYYY-MM-DD') || '',
			endDate: dayjs(dateRange?.end)?.format('YYYY-MM-DD') || '',
		}));
		setOpenPicker(false);
	};

	const resetSelection = (): void => {
		setDateRange({
			start: dayjs(),
			end: dayjs().add(1, 'day'),
		});
		setSelectedShortcut(null);
	};

	const handleStartDateChange = (newDate: Dayjs | null): void => {
		setDateRange((prev) => ({
			...prev,
			start: newDate,
		}));
	};

	const handleEndDateChange = (newDate: Dayjs | null): void => {
		setDateRange((prev) => ({
			...prev,
			end: newDate,
		}));
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ro">
			<TextField
				focused={false}
				label={inputLabel}
				size="small"
				value={startDate && endDate ?
					`${dayjs(startDate).format('DD.MMMYYYY')} - ${dayjs(endDate).format('DD.MMMYYYY')}` : ''
				}
				onClick={() => setOpenPicker(true)}
			/>

			<SimpleDialogTransition
				open={openPicker}
				setOpen={setOpenPicker}
				title="Alege intervalul de timp"
				titleProps={{ sx: { paddingLeft: '186px' } }}
				maxWidth='900px'
				ctaSection={
					<Flex gap={3} pb={3}>

						<Button
							variant="outlined"
							color="error"
							size='large'
							onClick={resetSelection}
						>
							Resetare
						</Button>

						<Button
							variant="contained"
							size='large'
							onClick={handleSave}
						>
							Salvează
						</Button>
					</Flex>
				}
			>
				<Flex
					width="100%"
					sx={{ alignItems: 'flex-start', gap: 2 }}
				>
					{/* Left side with shortcut boxes */}
					<Flex width={'170px'} column sx={{ alignItems: 'flex-end', gap: 2, mt: 7 }}>
						{shortcuts.map((shortcut) => (
							<Button
								key={shortcut.type}
								variant={selectedShortcut === shortcut.type ? 'contained' : 'outlined'}
								size="small"
								onClick={() => handleShortcut(shortcut.type)}
							>
								{shortcut.label}
							</Button>
						))}
					</Flex>

					{/* Divider */}
					<Box
						sx={{
							width: '1px',
							height: '328px',
							borderLeft: '1px solid #919191',
							mt: 5
						}}
					/>

					{/* Right side with calendars */}
					<Flex
						sx={{
							flex: 1
						}}
					>
						<Flex width={'100%'} sx={{
							justifyContent: 'space-around',
							borderBottom: '1px solid #dcdcdc',
							pb: 2
						}}>
							<Typography>Intervalul începe</Typography>
							<Typography>Intervalul se finalizează</Typography>
						</Flex>
						<DateCalendar
							defaultValue={dayjs()}
							value={dateRange.start}
							onChange={handleStartDateChange}
						/>
						{/* Divider */}
						<Box
							sx={{
								width: '1px',
								height: '320px',
								borderLeft: '1px solid #dcdcdc'
							}}
						/>
						<DateCalendar
							defaultValue={dayjs()}
							value={dateRange.end}
							onChange={handleEndDateChange}
						/>
					</Flex>
				</Flex>
			</SimpleDialogTransition>
		</LocalizationProvider>
	);
};