import { createContext, useContext } from 'react';
import dayjs from 'dayjs';
import { CreateProjectContextType } from './types';

export const CreateProjectContext = createContext<CreateProjectContextType>({
	step: 0,
	setStep: () => {},
	data: {
		description: 'Adaugă descriere',
		name: 'Proiect',
		status: 'draft',
		stage: 'in progress',
		type: undefined,
		end: dayjs().valueOf(),
		start: dayjs().valueOf(),
		elements: []
	},
	hasPhases: false,
	isDrawing: false,
	isEditing: false,
	setData: () => {},
	setHasPhases: () => {},
	setIsDrawing: () => {},
	handleAddPhase: () => {},
	handleRemovePhase: () => {},
	handleCreate: () => {},
	handleEdit: () => {},
	disbleEditing: true,
	isLoading: false,
	nameError: undefined,
	setNameErroer: () => {},
});

export const useCreateProjectContext = (): CreateProjectContextType => useContext(CreateProjectContext);