import { createContext, useContext } from 'react';
import { CategoryType, ThematicType } from './types';

type StateCallbackType<T> = (e: T) => T;

type MapDataContextType = {
  selectedThematics: ThematicType[];
  selectedCategories: CategoryType[];
  drawerOpen: boolean;
  dataId: string;
  layerId: string;
  layersList: any[];
  selectedFeatureObj: any | null;
  setSelectedThematics: (
    e: ThematicType[] | StateCallbackType<ThematicType[]>,
  ) => void;
  setSelectedCategories: (
    e: CategoryType[] | StateCallbackType<CategoryType[]>,
  ) => void;
  setDrawerOpen: (e: boolean) => void;
  setDataId: (e: StateCallbackType<string> | string) => void;
  setLayerId: (e: StateCallbackType<string> | string) => void;
  setLayersList: (e: any[]) => void;
  setSelectedFeatureObj: (
    e: StateCallbackType<any | null> | any | null,
  ) => void;
};

export const MapDataContext = createContext<MapDataContextType>({
	selectedThematics: [],
	selectedCategories: [],
	drawerOpen: false,
	dataId: '',
	layerId: '',
	layersList: [],
	selectedFeatureObj: null,
	setSelectedThematics: () => {},
	setSelectedCategories: () => {},
	setDrawerOpen: () => {},
	setDataId: () => {},
	setLayerId: () => {},
	setLayersList: () => {},
	setSelectedFeatureObj: () => {},
});

export const useMapDataContext = (): MapDataContextType => useContext(MapDataContext);
