import { ContactsOutlined } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '@/providers';
import { HeaderRightSide, HeaderRoleButton, HeaderUserName, StyledBadge } from '../styled';

export const UserSide: FC = () => {
	const { user } = useAuthContext();
	const userName = `${user?.firstName} ${user?.lastName}`;
	const navigate = useNavigate();

	return (
		<HeaderRightSide>

			{user?.role === 'moderator' && <ContactsOutlined />}
			<StyledBadge
				onClick={() => navigate('/common/profile')}
				overlap="circular"
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				variant="dot"
				sx={{ cursor: 'pointer' }}
			>
				<Avatar alt={userName} src={user?.avatar || ''} />
			</StyledBadge>
			<HeaderUserName>{userName.toUpperCase()}</HeaderUserName>

			<HeaderRoleButton size="small">
				{user?.role?.toUpperCase()}
			</HeaderRoleButton>

		</HeaderRightSide>
	);
};