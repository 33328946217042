import { AxiosError } from 'axios';
import { get } from '@/utils';
import { ISubscribedProjects } from '../use-app-users/types';

async function fetchSubscriptions(
	userId: string,
	page: number, items: number,
	onResponse?: (page: number, items: number) => void
): Promise<ISubscribedProjects[]> {
	try {
		const res = await get(`api/protected/users/${userId}/subscriptions?page=${page + 1}&items=${items}`);
		onResponse && onResponse(res.data?.pages, res?.data?.totalItems);
		const data = res.data as unknown as { subscribedProjects: ISubscribedProjects[] };
		return data?.subscribedProjects || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm datele.');
	}
}

async function fetchQuerySubscriptions(
	userId: string,
	query: string, page: number, items: number,
	onResponse?: (page: number, items: number) => void
): Promise<ISubscribedProjects[]> {
	try {
		const res = await get(`api/protected/users/${userId}/subscriptions${query}&page=${page + 1}&items=${items}`);
		onResponse && onResponse(res.data?.pages, res?.data?.totalItems);
		const data = res.data as unknown as { subscribedProjects: ISubscribedProjects[] };
		return data?.subscribedProjects || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm datele.');
	}
}

export { fetchSubscriptions, fetchQuerySubscriptions };