import { projectActions, projectReducer } from './project/slice';
import { surveyActions, surveyReducer } from './survey/slice';
import { utilsReducer, utilsActions } from './utils/slice';

export const reducers = {
	utils: utilsReducer,
	project: projectReducer,
	survey: surveyReducer,
};

export const actions = {
	...utilsActions,
	...projectActions,
	...surveyActions,
};
