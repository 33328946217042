import { FilterList } from '@mui/icons-material';
import { FC } from 'react';
import { ButtonBase } from './stylde';

interface IFilterButtonProps {
    handleClick?: () => void;
    isActive: boolean;
}

export const FilterButton: FC<IFilterButtonProps> = ({ isActive, handleClick}) => (
	<ButtonBase onClick={handleClick} isActive={isActive}>
		<FilterList sx={{ mr: 1 }} />
		Filtre
	</ButtonBase>
);

FilterButton.defaultProps = {
	handleClick: undefined
};