import { AxiosError } from 'axios';
import { get, post } from '@/utils';
import { ICheckAssignation } from './types';
import { IAssignedModeratorFSP } from '../use-moderators/types';

async function fetchModeratorsAssign(
	modId: string, page: number, items: number,
	onResponse?: (page: number, items: number) => void
): Promise<IAssignedModeratorFSP[]> {
	try {
		const res = await get(`api/protected/moderators/${modId}/assigned-fsps?page=${page + 1}&items=${items}`);
		onResponse && onResponse(res.data?.pages, res?.data?.totalItems);
		const data = res.data as unknown as { assignedFsps: IAssignedModeratorFSP[] };
		return data?.assignedFsps || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm datele.');
	}
}

async function postCheckAssign(params: ICheckAssignation): Promise<string> {
	try {
		const res = await post('api/protected/moderators/check-assignation', { ...params });
		return res.data?.message;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { message: string };
		return data?.message;
	}
}

export { fetchModeratorsAssign, postCheckAssign };