import { Box, BoxProps, styled } from '@mui/material';
import { FC } from 'react';

interface IMainLayoutProps extends BoxProps {
	isDrawerOpen: boolean;
	headerSize?: number;
}

const exclude =['isDrawerOpen', 'headerSize'];

export const MainLayoutBase: FC<IMainLayoutProps> = styled(Box as FC<IMainLayoutProps>, {
	shouldForwardProp: (prop: string) => !exclude.includes(prop),
})(({ theme, isDrawerOpen, headerSize }) => ({
	width: '100%',
	height: '100%',
	backgroundColor: theme.palette.grey[100],
	position: 'relative',
	paddingTop: headerSize || 106,
	paddingLeft: isDrawerOpen ? 250 : 128,
	paddingBottom: 16,
	paddingRight: 16,
	transition: 'all 0.2s ease-in-out'
}));

export const MainLayoutContent: FC<BoxProps> = styled(Box as FC<BoxProps>)(() => ({
	width: '100%',
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	position: 'relative',
	transition: 'all 0.2s ease-in-out',
	borderRadius: '16px',
}));
