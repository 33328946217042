import { createContext, useContext } from 'react';
import dayjs from 'dayjs';
import { IControllConsultationsContext, ISurveyDetails } from './types';

export const initialDetails: ISurveyDetails = {
	description: '',
	name: '',
	status: 'draft',
	expiry: dayjs().valueOf()
};

export const ControllConsultationsContext = createContext<IControllConsultationsContext>({
	survey: [],
	setSurvey: () => {},
	addSurvey: () => {},
	removeSurvey: () => {},
	copyPasteSurvey: () => {},
	selectedItem: null,
	setSelectedItem: () => {},
	addOption: () => {},
	editOption: () => {},
	removeOption: () => {},
	handleCreate: () => {},
	details: initialDetails,
	setDetails: () => {},
	loadingCTA: false,
	openDetails: false,
	setOpenDetails: () => {},
	handleEdit: () => {},
	compareObj: '',
	setCompareObj: () => {},
	shouldEdit: false
});

export const useControllConsultationsContext = (): 
    IControllConsultationsContext => useContext(ControllConsultationsContext);