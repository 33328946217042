import { Logout } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';
import { useAuthContext } from '@/providers';

interface IDrawerFooter {
    isOpen: boolean;
}

export const DrawerFooter: FC<IDrawerFooter> = ({ isOpen }) => {
	const { signOut } = useAuthContext();

	return (
		<Box pl={isOpen ? 1 : '10px'} pt={2}
			sx={{ borderTop: '1px solid #fff'}}>
			<Button onClick={() => signOut()} >
				<Logout />
				{isOpen && <Typography>DELOGARE</Typography>}
			</Button>
		</Box>
	);
};