import { Box, Button, ButtonGroup, CircularProgress, Step, StepButton, Stepper } from '@mui/material';
import { FC } from 'react';
import { ICreateProject } from '@/providers/create-project/types';
import { notify, stateSetter } from '@/utils';
import { Flex } from '../flex';

interface CustomStepperProps {
	steps: string[];
	activeStep: number;
	setActiveStep: stateSetter<number>;
	onLastStep?: () => void;
	isEditing?: boolean;
	disable: boolean;
	loading: boolean;
	data: ICreateProject;
	nameError: string | undefined;
}

export const CustomStepper: FC<CustomStepperProps> = ({
	activeStep, setActiveStep, steps, onLastStep, isEditing, disable, loading, data, nameError
}) => {

	const handleNext = (): void => {
		if (activeStep === 1) {
			let isValid = true; // Flag to track overall validity

			if (data?.type === undefined) {
				notify.warning('Selectează tipul de proiect pentru a continua.');
				isValid = false;
			}

			if (!data?.gallery || (data?.gallery.length === 0)) {
				notify.warning('Este obligatoriu să adaugi minim o imagine.');
				isValid = false;
			}

			if (data?.name?.length === 0) {
				notify.warning('Este obligatoriu să denumești proiectul.');
				isValid = false;
			}

			const strippedProjectDescription = data?.description?.replace(/<[^>]+>/g, '').trim();

			if (!strippedProjectDescription || strippedProjectDescription.length === 0) {
				notify.warning('Proiectul trebuie să conțină o descriere validă.');
				isValid = false;
			}

			if (isValid) {
				setActiveStep((prev) => prev + 1);
			}
		} else {
			setActiveStep((prev) => prev + 1);
		}
	};

	const handleBack = (): void => {
		setActiveStep((prev) => prev - 1);
	};

	const handleStep = (step: number) => (): void => {
		if (step === 2 && (activeStep === 1 || activeStep === 0)) {
			let isValid = true;

			if (data?.type === undefined) {
				notify.warning('Selectează tipul de proiect pentru a continua.');
				isValid = false;
			}

			if (!data?.gallery || (data?.gallery.length === 0)) {
				notify.warning('Este obligatoriu să adaugi minim o imagine.');
				isValid = false;
			}

			if (data?.name?.length === 0) {
				notify.warning('Este obligatoriu să denumești proiectul.');
				isValid = false;
			}

			const strippedProjectDescription = data?.description?.replace(/<[^>]+>/g, '').trim();

			if (!strippedProjectDescription || strippedProjectDescription.length === 0) {
				notify.warning('Proiectul trebuie să conțină o descriere validă.');
				isValid = false;
			}

			if (isValid) {
				setActiveStep(step);
			}
		} else {
			setActiveStep(step);
		}
	};

	return (
		<Flex sx={{ width: 'calc(100% - 200px)', justifyContent: 'space-between' }}>
			<Box sx={{ width: 'calc(100% - 300px)' }}>
				<Stepper nonLinear activeStep={activeStep}>
					{steps.map((label, index) => (
						<Step key={label} completed={activeStep > index}>
							<StepButton color="inherit" onClick={handleStep(index)}>
								{label}
							</StepButton>
						</Step>
					))}
				</Stepper>
			</Box>
			<Flex>
				<ButtonGroup>
					<Button variant='outlined' disabled={activeStep === 0}
						onClick={handleBack} >
						Înapoi
					</Button>
					{activeStep !== 2 ?
						<Button variant='contained' disabled={
							activeStep === 2 || activeStep === 1 && nameError !== undefined
						} onClick={handleNext}>
							Înainte
						</Button> :
						<Button variant='contained' onClick={onLastStep} disabled={disable || loading}
							sx={{ minWidth: '160px!important' }}>
							{/* eslint-disable-next-line */}
							{loading ? <CircularProgress sx={{ width: '20px!important', height: '20px!important' }} /> :
								isEditing ? 'Actualizează' : 'Creează Proiect'
							}
						</Button>
					}
				</ButtonGroup>
			</Flex>
		</Flex>
	);
};

CustomStepper.defaultProps = {
	onLastStep: undefined,
	isEditing: false,
};