import { DeleteOutline } from '@mui/icons-material';
import { Button, IconButton, Tooltip } from '@mui/material';
import { FC, useState } from 'react';
import { SimpleDialogTransition } from '../simple-dialog-transition';
import { IDeleteModalProps } from './types';

export const DeleteModal: FC<IDeleteModalProps> = ({ 
	children, 
	onDelete, 
	title, 
	isIcon=true,
	buttonProps,
	isDisabled=false,
}) => {
	const [open, setOpen] = useState(false);

	const handleClickOpen = (): void => {
		setOpen(true);
	};

	const handleClose = (): void => {
		setOpen(false);
	};

	const handleDelete = (): void => {
		onDelete();
		setOpen(false);
	};

	return (
		<>
			{isIcon ? 
				<Tooltip title='Șterge'><span>
					<IconButton onClick={handleClickOpen} disabled={isDisabled} {...buttonProps} sx={{ width: 'auto'}}>
						<DeleteOutline />
					</IconButton>
				</span></Tooltip> : 
				<Button variant='contained' disabled={isDisabled} onClick={handleClickOpen} 
					{...buttonProps}>
					Șterge
				</Button>
			}
			<SimpleDialogTransition open={open} setOpen={setOpen} title={title}
				ctaSection={<>
					<Button variant='outlined' onClick={handleClose}>ANULEAZĂ</Button>
					<Button variant='contained' onClick={handleDelete}>Da</Button>
				</>}
			>
				{children}
			</SimpleDialogTransition>
		</>
	);
};