/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { IProjectSlice } from './types';

const initialState: IProjectSlice = {
	id: undefined,
	announcementProject: undefined,
	consultationProject: undefined,
	openProjectSider: false,
	geometryToRemove: undefined
};

const projectSlice = createSlice({
	name: 'project',
	initialState,
	reducers: {
		setId: (state, action) => {
			state.id = action.payload;
		},
		setGeometryToDelete: (state, action) => {
			state.geometryToRemove = action.payload;
		},
		setConsultationProject: (state, action) => {
			state.consultationProject = action.payload;
		},
		setAnnouncementProject: (state, action) => {
			state.announcementProject = action.payload;
		},
		setOpenProject: (state, action) => {
			state.openProjectSider = action.payload;
		},
		clearProjects: (state) => {
			state.announcementProject = initialState.announcementProject;
			state.consultationProject = initialState.consultationProject;
			state.id = initialState.id;
			state.openProjectSider = initialState.openProjectSider;
		},
	},
});

export const projectReducer = projectSlice.reducer;
export const projectActions = projectSlice.actions;
