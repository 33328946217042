import { BoxProps } from '@mui/material';

export const extendedProps = ['column', 'justifyCenter', 'justifyEnd', 'alignStart'];

export interface FlexProps extends Omit<BoxProps, 'display'> {
  column?: boolean;
  justifyCenter?: boolean;
  justifyEnd?: boolean;
  alignStart?: boolean;
}
