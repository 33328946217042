import { TablePagination } from '@mui/material';
import { FC } from 'react';
import { ITableFetchPaginationProps } from './types';
import CustomTablePaginationActions from './CustomTablePaginationActions';

export const TableFetchPagination: FC<ITableFetchPaginationProps> = ({
	totalPages,
	page,
	items,
	setPage,
	setItems,
	totalItems
}): JSX.Element => {
	const getRowsPerPageOptions = (): number[] => [3, 5, 10];

	const handlePageChange = (_: unknown, newPage: number): void => {
		newPage <= totalPages && setPage(newPage);
	};

	const handleItemsChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setItems(Number(event.target.value));
		setPage(0); // Reset to the first page when rows per page changes
	};

	return (
		<TablePagination
			component="div"
			labelRowsPerPage={'Rânduri pe pagină:'}
			rowsPerPage={items}
			rowsPerPageOptions={getRowsPerPageOptions()}
			count={totalItems || items * totalPages}
			page={page}
			onPageChange={handlePageChange}
			onRowsPerPageChange={handleItemsChange}
			ActionsComponent={CustomTablePaginationActions}
			sx={{
				backgroundColor: '#fff',
				width: '100%',
				color: '#000',
			}}
		/>
	);
};
