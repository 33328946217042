import { styled, Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';

export const CellText = styled(Typography as FC<TypographyProps>)(() => ({
	fontSize: '14px',
	display: 'block',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	maxWidth: '100%',
	minWidth: '80px',
}));
