import {
	Box,
	BoxProps,
	CSSObject,
	Drawer as MuiDrawer,
	Theme,
	styled
} from '@mui/material';
import { FC } from 'react';

const drawerWidthOpen = '270px';
const drawerWidthClosed = '180px';

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidthOpen,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: drawerWidthClosed,
});

export const BaseDrawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	width: open ? drawerWidthOpen : drawerWidthClosed,
	flexShrink: 0,
	height: '100%',
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	'& .MuiDrawer-paper': {
		borderRadius: '16px',
		position: 'relative',
		justifyContent: 'space-between',
		overflow: 'auto',
		boxShadow: theme.shadows[10],
		...((open && openedMixin(theme)) || closedMixin(theme)),
	},
}));

export const DrawerContent = styled(Box as FC<BoxProps>)(() => ({
	width: '100%',
	height: '100%',
	maxHeight: 'calc(100% - 56px)',
	display: 'flex',
	flexDirection: 'column',
	marginBottom: '16px',
	overflowY: 'auto',
	overflowX: 'hidden'
}));