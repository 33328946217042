import { AxiosError } from 'axios';
import { edit } from '@/utils';
import { IUpdateAdminProfile, IUpdateFSPProfile, IUpdatePasswordProfile } from './types';

async function patchAdminProfile(updatedAdmin: IUpdateAdminProfile): Promise<string | number> {
	try {
		const res = await edit('api/protected/profile', {...updatedAdmin});
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să actualizăm profilul.');
	}
}

async function patchFSPProfile(updatedAdmin: IUpdateFSPProfile): Promise<string | number> {
	try {
		const res = await edit('api/protected/profile', {...updatedAdmin});
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să actualizăm profilul.');
	}
}

async function patchProfilePassword(updatedAdmin: IUpdatePasswordProfile): Promise<string | number> {
	try {
		const res = await edit('api/protected/profile/password', {...updatedAdmin});
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să actualizăm parola.');
	}
}

export { patchAdminProfile, patchProfilePassword, patchFSPProfile };