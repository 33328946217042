import { Tooltip, TooltipProps, TypographyProps } from '@mui/material';
import React, { FC } from 'react';
import { CellText } from './styled';

interface TableTextProps {
    text: string;
    TypoProps?: TypographyProps;
    ToolProps?: TooltipProps;
}

export const TableText: FC<TableTextProps> = ({ text, ToolProps, TypoProps }) => (
	<Tooltip title={text} {...ToolProps}>
		<CellText {...TypoProps} >
			{text}
		</CellText>
	</Tooltip>
);

TableText.defaultProps = {
	ToolProps: undefined,
	TypoProps: undefined
};