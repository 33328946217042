import { useCallback, useEffect, useState } from 'react';
import { notify, stateSetter } from '@/utils';
import { ICreateModerator, IModerator, IUpdateModerator, ModeratorsReturnType } from './types';
import {
	deleteModerator, fetchModerators, fetchQueryModerators, fetchSingleModerator,
	patchModerator, patchUnnasigned, postModerator
} from './actions';

interface ILoading {
	all: boolean;
	delete: boolean;
	create: boolean;
	update: boolean;
}

export function useModerators<T extends IModerator | IModerator[]>(
	getModeratorsOnRender: boolean | undefined = undefined,
	appModeratorId: string | undefined = undefined,
	queryParam: string | undefined = undefined
): ModeratorsReturnType<T> {
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState<ILoading>({ all: true, delete: false, create: false, update: false });
	const [enableQuery, setEnableQuery] = useState<boolean>(false);
	const [page, setPage] = useState<number>(0);
	const [items, setItems] = useState<number>(5);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [totalItems, setTotalItems] = useState<number>(1);

	const toggleLoading = (type: 'all' | 'delete' | 'create' | 'update', value: boolean): void => {
		setLoading((prevState) => ({
			...prevState,
			[type]: value,
		}));
	};

	const handleResponse = (pageParam: number, itemsParam: number): void => {
		setTotalPages(pageParam);
		setTotalItems(itemsParam);
	};

	const getModerators = useCallback(
		async (pageParam: number, itemsParam: number) => {
			try {
				toggleLoading('all', true);
				const response = await fetchModerators(pageParam, itemsParam, handleResponse);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('all', false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getModerator = useCallback(
		async (moderatorId: string) => {
			try {
				toggleLoading('all', true);
				const response = await fetchSingleModerator(moderatorId);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('all', false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getQueryModerators = useCallback(
		async (query: string, queryPage: number, queryItems: number) => {
			try {
				!enableQuery && setEnableQuery(true);
				toggleLoading('all', true);
				const response = await fetchQueryModerators(query, queryPage, queryItems, handleResponse);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('all', false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const removeModerator = useCallback(
		async (moderatorId: string, onSuccess?: () => void) => {
			try {
				toggleLoading('delete', true);
				const response = await deleteModerator(moderatorId);
				if (response === 200) {
					setData(currentData => {
						if (Array.isArray(currentData)) {
							return currentData.filter(moderator => moderator._id !== moderatorId) as T;
						}
						return currentData;
					});
					onSuccess && onSuccess();
				} else if (response === 400) {
					notify.error('Nu am reușit să ștergem moderatorul!');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('delete', false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const createModerator = useCallback(
		async (moderatorObj: ICreateModerator, onSuccess?: () => void) => {
			try {
				toggleLoading('create', true);
				const response = await postModerator(moderatorObj);
				if (response === 201) {
					onSuccess && onSuccess();
				} else if (response === 400) {
					notify.error('Nu am reușit să adăugăm moderatorul!');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('create', false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const editModerator = useCallback(
		async (moderatorId: string, updatedObject: IUpdateModerator, onSuccess?: () => void) => {
			try {
				toggleLoading('update', true);
				const response = await patchModerator(moderatorId, updatedObject);
				if (response === 200) {
					onSuccess && onSuccess();
				} else if (response === 400) {
					notify.error('Nu am reușit să actualizăm moderatorul!');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('update', false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const unnasignFspModerator = useCallback(
		async (moderatorId: string, fspId: string, onSuccess?: () => void) => {
			try {
				toggleLoading('update', true);
				const response = await patchUnnasigned(moderatorId, fspId);
				if (response === 200) {
					onSuccess && onSuccess();
				} else if (response === 400) {
					notify.error('Nu am reusit sa disociem administrația');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('update', false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	useEffect(() => {
		if (getModeratorsOnRender && queryParam === undefined) {
			getModerators(page, items);
		}
		// eslint-disable-next-line
	}, [getModeratorsOnRender, queryParam, page, items]);

	useEffect(() => {
		if (appModeratorId) {
			getModerator(appModeratorId);
		}
		// eslint-disable-next-line
	}, [appModeratorId]);

	useEffect(() => {
		if (queryParam && appModeratorId === undefined) {
			getQueryModerators(queryParam, page, items);
		} else if (appModeratorId === undefined && enableQuery) {
			getModerators(page, items);
		}
		// eslint-disable-next-line
	}, [queryParam, appModeratorId, page, items]);

	return {
		data: data || ([] as IModerator[] as T),
		setData: setData as stateSetter<T>,
		loading: loading.all,
		loadingDelete: loading.delete,
		loadingCreate: loading.create,
		loadingUpdate: loading.update,
		createModerator,
		editModerator,
		getModerator,
		getModerators,
		removeModerator,
		unnasignFspModerator,
		items,
		page,
		setItems,
		setPage,
		totalPages,
		totalItems
	};
}