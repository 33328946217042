import { styled } from '@mui/material';
import { FC } from 'react';
import { Flex, FlexProps } from '../flex';

export const FilterWrapper = styled(Flex as FC<FlexProps>)(({ theme }) => ({
	width: '100%',
	gap: theme.spacing(3),
	height: '77px',
	justifyContent: 'space-between',
	backgroundColor: theme.palette.grey[100],
	padding: theme.spacing(0, 2)
}));