import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { IMapProject, IMapQuery, MapProjectsReturnType } from './types';
import { fetchMapProjects } from './actions';
import { notify, stateSetter } from '@/utils';

export function useMapProjects<T extends IMapProject | IMapProject[]>(
	projQuery: IMapQuery | undefined = undefined,
): MapProjectsReturnType<T> {
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState<boolean>(true);

	const getMapProject = useCallback(
		async (queryParam: IMapQuery) => {
			try {
				setLoading(true);
				const response = await fetchMapProjects(queryParam);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
        [setData]
	);

	const debouncedFunction = debounce(
		(query: IMapQuery | undefined) => {
			if (query) {
				getMapProject(query);
			} else {
				setData(null);
			}
		},
		500
	);
	
	const debouncedGetMapProject = useCallback(
		(query: IMapQuery | undefined) => {
			debouncedFunction(query);
		},
		// eslint-disable-next-line
		[]
	);
	
	useEffect(() => {
		debouncedGetMapProject(projQuery);
	
		// eslint-disable-next-line
		return () => {
			debouncedFunction.cancel();
		};
		// eslint-disable-next-line
	}, [projQuery, debouncedGetMapProject]);

	return {
		data: data || ([] as IMapProject[] as T),
		setData: setData as stateSetter<T>,
		loading,
		getMapProject
	};
}