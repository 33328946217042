import axios, { AxiosError } from 'axios';
import { CITIZEN_API_URL } from '@/utils/axios/constants';
import { del } from '@/utils';
import { IAttachments } from './types';

async function postFiles(files: FileList): Promise<IAttachments[]> {
	try {
		const formData = new FormData();
		Array.from(files).forEach((file) => formData.append('files', file)); // Append each file with the key 'files'

		const response = await axios.post(`${CITIZEN_API_URL}/api/cloud/files`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			withCredentials: true
		});
		const receivedData = response.data as unknown as { attachments: IAttachments[]};
		return receivedData.attachments;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa incarcam fisierele.');
	}
};

async function postFile (file: File): Promise<string>{
	try {
		const formData = new FormData();
		formData.append('file', file);

		const response = await axios.post<string>(`${CITIZEN_API_URL}/api/cloud/file`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			withCredentials: true
		});
		const receivedData = response.data as unknown as {url: string};
		return receivedData.url;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa incarcam fisierul.');
	}
};

async function deleteFile (file: string): Promise<string | number>{
	try {
		const response = await del(`api/cloud/files?file=${file}`);
		return response?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să ștergem fisierul.');
	}
};

export { deleteFile, postFile, postFiles };
