/**
 * Types of possible notifications
 */
export enum Notification {
  ERR = 'notification_error',
  WARN = 'notification_warning',
  INF = 'notification_info',
  SUCC = 'notification_success',
}

/**
 * Interface describing a Notifier - helper used for Notifications
 */
export interface INotifier {
  /**
   * Method used to emit a error notification
   * @param message message of the notification
   */
  error: (message: string) => void;

  /**
   * Method used to emit a warning notification
   * @param message message of the notification
   */
  warning: (message: string) => void;

  /**
   * Method used to emit a info notification
   * @param message message of the notification
   */
  info: (message: string) => void;

  /**
   * Method used to emit a success notification
   * @param message message of the notification
   */
  success: (message: string) => void;

  /**
   * Method used to enable the notifications worker
   * @param errorCallback callback called on a error notifications
   * @param warningCallback callback called on a warning notifications
   * @param infoCallback callback called on a info notifications
   * @param successCallback callback called on a success notifications
   */
  enable: (
    errorCallback: (message: string) => void,
    warningCallback: (message: string) => void,
    infoCallback: (message: string) => void,
    successCallback: (message: string) => void,
  ) => void;
}
