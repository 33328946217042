import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { MainLayout } from '@/components';
import { useAuthContext } from '@/providers';
import { IRouteProps } from '../types';

export const AdminRoute: FC<IRouteProps> = ({ redirectPath }) => {
	const { user } = useAuthContext();
	if (user?.role !== undefined)
		return (
			<MainLayout>
				<Outlet />
			</MainLayout>
		);

	return <Navigate to={redirectPath} replace />;
};
