import { useCallback, useState } from 'react';
import { notify } from '@/utils';
import { patchBlock, patchBlockChat, patchMute, patchMuteChat, patchUnblock, patchUnmute } from './actions';
import { IRestrictObj, RestrictionsReturnType } from './types';
import { useSocketContext } from '@/providers';

export function useRestrictions(): RestrictionsReturnType {
	const [loading, setLoading] = useState<boolean>(false);
	const { restrictEmit } = useSocketContext();

	const unblockUser = useCallback(
		async (userId: string, onSuccess?: () => void) => {
			try {
				setLoading(true);
				const response = await patchUnblock(userId);
				if (response === 200) {
					onSuccess && onSuccess();
				} else if (response === 400) {
					notify.error('Nu am reusit sa deblocam utilizatorul!');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[]
	);

	const unmuteUser = useCallback(
		async (userId: string, onSuccess?: () => void) => {
			try {
				setLoading(true);
				const response = await patchUnmute(userId);
				if (response === 200) {
					onSuccess && onSuccess();
					restrictEmit({ status: 'unmute', userId, restrictObj: {} });
				} else if (response === 400) {
					notify.error('Nu am reusit sa derestrictionam utilizatorul!');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[]
	);

	const blockUser = useCallback(
		async (userId: string, restrictObj: IRestrictObj, onSuccess?: () => void) => {
			try {
				setLoading(true);
				const response = await patchBlock(userId, restrictObj);
				if (response === 200) {
					onSuccess && onSuccess();
					restrictEmit({ status: 'block', userId, restrictObj });
				} else if (response === 400) {
					notify.error('Nu am reusit sa blocam utilizatorul!');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[]
	);

	const blockChatUser = useCallback(
		async (userId: string, restrictObj: IRestrictObj, onSuccess?: (messages: string[]) => void) => {
			try {
				setLoading(true);
				const response = await patchBlockChat(userId, restrictObj);
				if (typeof (response) === 'object') {
					onSuccess && onSuccess(response?.messages);
					restrictEmit({ status: 'block', userId, restrictObj });
				} else if (response === 400) {
					notify.error('Nu am reusit sa blocam utilizatorul!');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[]
	);

	const muteUser = useCallback(
		async (userId: string, restrictObj: IRestrictObj, onSuccess?: () => void) => {
			try {
				setLoading(true);
				const response = await patchMute(userId, restrictObj);
				if (response === 200) {
					onSuccess && onSuccess();
					restrictEmit({ status: 'mute', userId, restrictObj });
				} else if (response === 400) {
					notify.error('Nu am reusit sa restrictionam utilizatorul!');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[]
	);

	const muteChatUser = useCallback(
		async (userId: string, restrictObj: IRestrictObj, onSuccess?: (messages: string[]) => void) => {
			try {
				setLoading(true);
				const response = await patchMuteChat(userId, restrictObj);
				if (typeof (response) === 'object') {
					onSuccess && onSuccess(response?.messages);
					restrictEmit({ status: 'mute', userId, restrictObj });
				} else if (response === 400) {
					notify.error('Nu am reusit sa restrictionam utilizatorul!');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[]
	);

	return {
		loading,
		blockUser,
		muteUser,
		unblockUser,
		unmuteUser,
		blockChatUser,
		muteChatUser
	};
}