import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
	Collapse,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText
} from '@mui/material';
import React, { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector, uuid } from '@/utils';
import { menuIcons } from '../data/menuIcons.data';
import { drawerTheme } from '../drawerTheme';
import { MenuItem } from '../menu-items';
import { CollapsedListItem } from './styled';

interface IState {
	menuTab: MenuItem;
}

interface MenuCollapseProps {
	menuTab: MenuItem;
	isOpen: boolean;
	isDrawerOpen: boolean;
}

const MenuCollapse: FC<MenuCollapseProps> = ({ menuTab, isOpen, isDrawerOpen }) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	return (
		<Collapse
			in={isOpen}
			timeout="auto"
			unmountOnExit
			sx={{ backgroundColor: isOpen ? drawerTheme.activeSubmenu : 'inherit' }}
		>
			<List component="li" disablePadding>
				{isDrawerOpen &&
					menuTab?.children?.map((child) => (
						<ListItemButton
							key={uuid()}
							onClick={() => navigate(child.url)}
							disableRipple
							sx={{
								backgroundColor: pathname.includes(child?.url)
									? drawerTheme.activeText
									: 'inherit'
							}}
						>
							<ListItemText
								primary={child.title.toUpperCase()}
								primaryTypographyProps={{
									marginLeft: '0px!important',
									sx: { fontSize: '11px!important' }
								}}
							/>
						</ListItemButton>
					))}
			</List>
		</Collapse>
	);
};

export const MenuCollapsed: FC<IState> = ({ menuTab }) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const tempOpen = pathname.includes(menuTab.url) && menuTab.url !== '/';
	const [open, setOpen] = useState(tempOpen);
	const { isDrawerOpen } = useAppSelector((state) => state.utils);
	const handleClick = (): void => {
		const callbackNeeded = isDrawerOpen
			? (): void => setOpen((prev) => !prev)
			: (): void => menuTab?.children && navigate(menuTab?.children[0]?.url);
		callbackNeeded();
	};

	return (
		<>
			<CollapsedListItem
				title={!isDrawerOpen ? menuTab?.title : ''}
				onClick={handleClick}
				sx={{ backgroundColor: open ? drawerTheme.active : 'inherit' }}
			>
				<ListItemIcon
					sx={{
						minWidth: 0,
						mr: 1,
						justifyContent: 'center'
					}}
				>
					{menuIcons[menuTab.icon] &&
						React.cloneElement(menuIcons[menuTab.icon], {
							color:
								pathname.includes(menuTab.url) && menuTab.url !== '/'
									? 'primary'
									: 'inherit'
						})}
				</ListItemIcon>
				{isDrawerOpen && (
					<>
						<ListItemText primary={menuTab.title.toUpperCase()} />
						{open ? <ExpandLess /> : <ExpandMore />}
					</>
				)}
			</CollapsedListItem>
			<MenuCollapse menuTab={menuTab} isOpen={open} isDrawerOpen={isDrawerOpen} />
		</>
	);
};
