import { FC, ReactNode, useState, useMemo } from 'react';
import { MapDataContext } from './context';
import { CategoryType, ThematicType } from './types';

export const MapDataProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [selectedThematics, setSelectedThematics] = useState<ThematicType[]>(
		[],
	);
	const [selectedCategories, setSelectedCategories] = useState<CategoryType[]>(
		[],
	);
	const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
	const [dataId, setDataId] = useState<string>('');
	const [layerId, setLayerId] = useState<string>('');
	const [layersList, setLayersList] = useState<any[]>([]);
	const [selectedFeatureObj, setSelectedFeatureObj] = useState<any | null>(
		null,
	);

	const contextValue = useMemo(
		() => ({
			selectedCategories,
			setSelectedCategories,
			selectedThematics,
			setSelectedThematics,
			drawerOpen,
			setDrawerOpen,
			dataId,
			setDataId,
			layerId,
			setLayerId,
			layersList,
			setLayersList,
			selectedFeatureObj,
			setSelectedFeatureObj,
		}),
		[
			selectedCategories,
			selectedThematics,
			drawerOpen,
			dataId,
			layerId,
			layersList,
			selectedFeatureObj,
		],
	);

	return (
		<MapDataContext.Provider value={contextValue}>
			{children}
		</MapDataContext.Provider>
	);
};
