import { styled } from '@mui/material';
import { FC } from 'react';
import { Flex, FlexProps } from '../../flex';

export const HeroWrapperContainer = styled(Flex as FC<FlexProps>)(({ theme }) => ({
	width: '100%',
	height: '100%',
	display: 'block',
	flexWrap: 'nowrap',
	borderRadius: '16px',
	backgroundColor: theme.palette.common.white,
	overflow: 'auto',
	boxShadow: theme.shadows[10],
	gap: '16px'
}));