import { ChevronLeft } from '@mui/icons-material';
import { Box, styled } from '@mui/material';
import { FC, ReactNode } from 'react';
import { WhiteLogo } from '@/assets';
import { ToggleButtonContainer } from './styled';

const Wrapper: FC<{ children: ReactNode }> = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	position: 'relative',
	padding: theme.spacing(0, 1),
	width: '100%',
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	height: '86px',
}));

interface IProps {
	open: boolean;
	closeDrawer: () => void;
	isHovered: boolean;
}

export const DrawerHeader: FC<IProps> = ({ open, closeDrawer, isHovered }) => (
	<Wrapper>
		<Box component={'img'} alt='white-logo' src={WhiteLogo} sx={{
			width: open ? '69px' : '56px',
			height: 'auto',
			maxHeight: '56px',
			maxWidth: '69px',
		}} />
		<ToggleButtonContainer isHovered={isHovered}>
			<ChevronLeft
				onClick={closeDrawer} aria-label="toggle" aria-labelledby="toggle"
				sx={{
					transform: `rotate(${open ? 0 : 180}deg)`,
					transition: 'all 0.3s ease-in',
				}}
			/>
		</ToggleButtonContainer>
	</Wrapper>
);