import { ComponentType, FC, Suspense } from 'react';
import { CircularProgress } from '@mui/material';
import { Flex } from '../flex';

const GlogalLoading: FC = () => <Flex width={'100%'} justifyCenter>
	<CircularProgress />
</Flex>;

export function Loadable<P extends object>(Component: ComponentType<P>): FC<P> {
	return (props: P) => (
		<Suspense fallback={<GlogalLoading />}>
			<Component {...props} />
		</Suspense>
	);
}
