import { Outlet, Navigate } from 'react-router-dom';
import { FC } from 'react';
import { IRouteProps } from '../types';
import { MinimalLayout } from '@/components';
import { useAuthContext } from '@/providers';

export const AuthRoute: FC<IRouteProps> = ({ redirectPath }) => {
	const { user } = useAuthContext();
	if (user?.role === undefined)
		return (
			<MinimalLayout>
				<Outlet />
			</MinimalLayout>
		);

	return <Navigate to={redirectPath} replace />;
};

export const RedirectToAuth: FC = () => <Navigate to="/auth" replace />;
