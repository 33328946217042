import { createContext, useContext } from 'react';
import { IConsultationsContext } from './types';

export const ConsultationsContext = createContext<IConsultationsContext>({
	loadingProject: true,
	projects: null,
	selectedProject: null,
	setSelectedProject: () => { },
	handleSelectProject: () => { },
	consultation: [],
	setConsultation: () => { },
	loadingCTA: false,
	loadingConsultation: false,
	getConsultationsQuery: () => { },
	handleRemove: () => { },
	setQueryCons: () => { },
	items: 5,
	page: 0,
	setItems: () => { },
	setPage: () => { },
	totalPages: 1,
	totalItems: 1
});

export const useConsultationsContext = (): IConsultationsContext => useContext(ConsultationsContext);