/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { ISurveySlice } from './types';

const initialState: ISurveySlice = {
	survey: []
};

const surveySlice = createSlice({
	name: 'survey',
	initialState,
	reducers: {
		setSurvey: (state, action) => {
			state.survey = action.payload;
		},
	},
});

export const surveyReducer = surveySlice.reducer;
export const surveyActions = surveySlice.actions;
