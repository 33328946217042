import { createContext, useContext } from 'react';
import { MapLayersContextType } from './types';

export const MapLayersContext = createContext<MapLayersContextType>({
	zoom: 7.5,
	setZoom: () => {},
	center: [2831408, 5796201],
	setCenter: () => {},
	boundingBox: {
		top: 49,
		left: 19,
		bottom: 43,
		right: 31.5,
	},
	setBoundingBox: () => {},
});

export const useMapLayersContext = (): MapLayersContextType => useContext(MapLayersContext);
