import { Box, BoxProps, styled } from '@mui/material';
import { FC } from 'react';

interface IFilterContainerProps extends BoxProps {
    isComplex?: boolean;
}

export const FilterContainer: FC<IFilterContainerProps> = styled(Box as FC<IFilterContainerProps>, {
	shouldForwardProp: (prop: string) => prop !== 'isComplex'
})(({ isComplex = false }) => ({
	width: '100%',
	height: isComplex ? '176px' : '124px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: '0 0 0 8px',
}));