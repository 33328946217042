import { ChevronLeft } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FC } from 'react';
import { Flex } from '../../flex';
import { DrawerTitle } from './styled';

interface IProps {
  open: boolean;
  closeDrawer: () => void;
  title: string;
}

export const SimpleDrawerHeader: FC<IProps> = ({ open, closeDrawer, title }) => (
	<Flex sx={{ justifyContent: 'space-between', margin: '8px 0 8px 8px'}}>
		<DrawerTitle>{title}</DrawerTitle>
		<IconButton onClick={closeDrawer} aria-label="toggle" aria-labelledby="toggle">
			<ChevronLeft
				fontSize="medium"
				sx={{
					transform: `rotate(${open ? 0 : 180}deg)`,
					transition: 'all 0.3s ease-in',
				}}
			/>
		</IconButton>
	</Flex>
);
