import { useCallback, useEffect, useState } from 'react';
import { notify, stateSetter } from '@/utils';
import { ICheckAssignation, ModeratorsReturnType } from './types';
import { fetchModeratorsAssign, postCheckAssign } from './actions';
import { IAssignedModeratorFSP } from '../use-moderators/types';

export function useModeratorsAssign<T extends IAssignedModeratorFSP | IAssignedModeratorFSP[]>(
	moderatorId: string | undefined = undefined,
	refresh: any | undefined = undefined
): ModeratorsReturnType<T> {
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [loadingAssign, setLoadingAssign] = useState<boolean>(false);
	const [page, setPage] = useState<number>(0);
	const [items, setItems] = useState<number>(5);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [totalItems, setTotalItems] = useState<number>(1);

	const handleResponse = (pageParam: number, itemsParam: number): void => {
		setTotalPages(pageParam);
		setTotalItems(itemsParam);
	};

	const getAssign = useCallback(
		async (idParam: string, pageParam: number, itemsParam: number) => {
			try {
				setLoading(true);
				const response = await fetchModeratorsAssign(idParam, pageParam, itemsParam, handleResponse);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const checkAssign = useCallback(
		async (params: ICheckAssignation, onError?: (message: string) => void, onSuccess?: () => void) => {
			try {
				setLoadingAssign(true);
				const response = await postCheckAssign(params);
				if (response === 'OK') {
					onSuccess && onSuccess();
				} else {
					onError && onError(response !== 'OK' ? response :
						`Administrația selectată nu oferă servicii în județul ${params?.moderator?.county}
                        , UAT ${params?.moderator?.uat}. Sunteți sigur că doriți să continuați cu asocierea?`
					);
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingAssign(false);
			}
		},
		// eslint-disable-next-line
		[]
	);

	useEffect(() => {
		if (moderatorId) {
			getAssign(moderatorId, page, items);
		}
		// eslint-disable-next-line
	}, [moderatorId, page, items, refresh]);

	return {
		data: data || ([] as IAssignedModeratorFSP[] as T),
		setData: setData as stateSetter<T>,
		loading,
		loadingAssign,
		getAssign,
		items,
		page,
		setItems,
		setPage,
		totalPages,
		totalItems,
		checkAssign
	};
}