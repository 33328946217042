import {
	Autocomplete,
	CircularProgress,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField
} from '@mui/material';
import React from 'react';
import { useCSC } from '@/hooks';
import { HeaderFilterCta } from '../header-filters-cta';
import { initialComplexFilter, useFiltersContext } from '@/providers/filters-provider/context';
import { IComplexFilter } from '@/providers/filters-provider/types';

export const ComplexFilterForm: React.FC = () => {
	const { applyFilter, complexFilter, setComplexFilter, clearFilter } = useFiltersContext();

	const {
		counties, error: errorCounty, loading: loadingCounty,
		cities, errorCity, loadingCity
	} = useCSC(complexFilter.county?.length > 0 ? complexFilter.county : undefined);

	const StatusOptions = [
		{ value: 'published', label: 'Publicat' },
		{ value: 'draft', label: 'Draft' },
		{ value: 'archived', label: 'Arhivat' }
	];;
	const PublishOptions = [
		{ value: 'planned', label: 'Planificat' },
		{ value: 'in progress', label: 'În desfășurare' },
		{ value: 'completed', label: 'Complet' },
	];;

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		setComplexFilter(prevState => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleSelectChange = (event: SelectChangeEvent): void => {
		const name = event.target.name as keyof IComplexFilter;
		const value = event.target.value as string;
		setComplexFilter(prevState => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleAutocompleteChange = (key: 'county' | 'uat', newValue: string): void => {
		setComplexFilter(prevState => ({
			...prevState,
			[key]: newValue,
		}));
	};

	const handleClear = (): void => {
		setComplexFilter(initialComplexFilter);
		clearFilter();
	};

	const handleApply = (): void => {
		applyFilter();
	};

	const getStatusColor = (status: string): string => {
		switch (status) {
			case 'published':
				return 'green';
			case 'draft':
				return 'orange';
			case 'archived':
				return 'purple';
			default:
				return 'grey';
		}
	};

	return (
		<form noValidate autoComplete="off" style={{ width: '100%', paddingRight: '16px' }}>
			<Grid container spacing={2} alignItems="center" columnSpacing={3} rowSpacing={1} mb={1}>
				<Grid item xs={12} sm={4}>
					<TextField
						name="name"
						label="Nume proiect"
						variant="outlined"
						value={complexFilter.name}
						onChange={handleInputChange}
						fullWidth
						placeholder='Write a name'
						size='small'
					/>
				</Grid>
				<Grid item xs={12} sm={4}>
					<FormControl fullWidth variant="standard" size="small">
						<Autocomplete
							options={Object.entries(counties).map(([key]) => key)}
							value={complexFilter.county}
							onChange={(e, newValue) => handleAutocompleteChange('county', newValue!)}
							onFocus={() => setComplexFilter((prev) => ({
								...prev,
								uat: ''
							}))}
							disabled={loadingCounty || !!errorCounty}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Județ"
									variant="standard"
									size='small'
									error={!!errorCounty}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<>
												{loadingCounty ? <CircularProgress color="inherit" size={20} /> : null}
												{params.InputProps.endAdornment}
											</>
										),
									}}
								/>
							)}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={4}>
					<FormControl fullWidth variant="standard" size="small">
						<Autocomplete
							options={cities}
							value={complexFilter.uat}
							onChange={(event, newValue) => handleAutocompleteChange('uat', newValue!)}
							disabled={loadingCity || !!errorCity || cities.length === 0}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Localitatea"
									size='small'
									variant="standard"
									error={!!errorCity}
									helperText={errorCity ? 'Nu am putut accesa localitățile' : ''}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<>
												{loadingCity ? <CircularProgress color="inherit" size={20} /> : null}
												{params.InputProps.endAdornment}
											</>
										),
									}}
								/>
							)}
						/>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={4}>
					<TextField
						name="fsp"
						label="Institutie administrație"
						variant="outlined"
						value={complexFilter.fsp}
						onChange={handleInputChange}
						fullWidth
						placeholder='Caută institutie'
						size='small'
					/>
				</Grid>
				<Grid item xs={12} sm={4}>
					<FormControl fullWidth variant="standard" size="small">
						<InputLabel id="status-label">Status execuție</InputLabel>
						<Select
							labelId="status-label"
							id="status"
							name="status"
							aria-label="status"
							value={complexFilter.status}
							onChange={handleSelectChange}
							sx={{ color: getStatusColor(complexFilter.status) }}
						>
							{StatusOptions.map(status => (
								<MenuItem key={`${status?.value}-status`} value={status?.value}>
									{status?.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>

				</Grid>
				<Grid item xs={12} sm={4}>
					<FormControl fullWidth variant="standard" size="small">
						<InputLabel id="publish-label">Status publicare</InputLabel>
						<Select
							labelId="publish-label"
							id="publish"
							name="stage"
							aria-label="publish"
							value={complexFilter.stage}
							onChange={handleSelectChange}
						>
							{PublishOptions.map(publishOption => (
								<MenuItem key={`${publishOption?.value}-stage`} value={publishOption?.value}>
									{publishOption?.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
			</Grid>
			<HeaderFilterCta isComplex onApply={handleApply} onClear={handleClear} />
		</form>
	);
};
