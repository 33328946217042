interface IDrawerTheme {
    background: string;
    active: string;
    activeSubmenu: string;
    activeText: string;
}

export const drawerTheme: IDrawerTheme = {
	background: '#0D47A1',
	active: '#1A237E',
	activeSubmenu: '#1565C0',
	activeText: '#64B5F6',
};