import { useCallback, useEffect, useState } from 'react';
import { ICreateProjectSendData } from '@/providers/create-project/types';
import { notify, stateSetter } from '@/utils';
import {
	deleteProject, fetchPosterQr, fetchProjects, fetchQueryProjects,
	fetchSingleProject, patchProject, postProject,
	postProjectGeometry,
	postProjectName
} from './actions';
import { ICheckProjectName, IProject, ProjectsReturnType } from './types';
import { useSocketContext } from '@/providers';

export function useProjects<T extends IProject | IProject[]>(
	getProjectsOnRender: boolean | undefined = undefined,
	idParam: string | undefined = undefined,
	queryParam: string | undefined = undefined,
	getAllQuery: boolean | undefined = undefined,
	getAllProj: boolean | undefined = undefined
): ProjectsReturnType<T> {
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
	const { deleteProjectEmit: archiveEmmiter, addProjectEmit } = useSocketContext();
	const [loadingCTA, setLoadingCTA] = useState<boolean>(false);
	const [enableQuery, setEnableQuery] = useState<boolean>(false);
	const [page, setPage] = useState<number>(0);
	const [items, setItems] = useState<number>(5);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [totalItems, setTotalItems] = useState<number>(1);

	const handleResponse = (pageParam: number, itemsParam: number): void => {
		setTotalPages(pageParam);
		setTotalItems(itemsParam);
	};

	const getProjects = useCallback(
		async (allProj: boolean, pageParam: number, itemsParam: number) => {
			try {
				setLoading(true);
				const response = await fetchProjects(allProj, pageParam, itemsParam, handleResponse);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getQueryProjects = useCallback(
		async (allQuery: boolean, query: string, queryPage: number, queryItems: number) => {
			try {
				!enableQuery && setEnableQuery(true);
				setLoading(true);
				const response = await fetchQueryProjects(allQuery, query, queryPage, queryItems, handleResponse);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getProject = useCallback(
		async (projId: string) => {
			try {
				setLoading(true);
				const response = await fetchSingleProject(projId);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const createProject = useCallback(
		async (projectObject: ICreateProjectSendData, onSuccess?: () => void) => {
			try {
				setLoadingCTA(true);
				const response = await postProject(projectObject, addProjectEmit);
				if (response === 201) {
					onSuccess && onSuccess();
				} else if (response === 400) {
					notify.error('Nu am reușit să adăugăm proiectul.');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingCTA(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const updateProject = useCallback(
		async (projId: string, projectObject: Omit<ICreateProjectSendData, 'elements'>, onSuccess?: () => void) => {
			try {
				setLoadingUpdate(true);
				const response = await patchProject(projId, projectObject);
				if (response === 200) {
					if (projectObject.status === 'archived') {
						archiveEmmiter(projId);
					}
					if (projectObject.status === 'published') {
						addProjectEmit(projId);
					}
					onSuccess && onSuccess();
				} else if (response === 400) {
					notify.error('Nu am reușit să actualizăm proiectul.');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingUpdate(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const removeProject = useCallback(
		async (projectId: string, onSuccess?: () => void) => {
			try {
				setLoading(true);
				const response = await deleteProject(projectId);
				if (response === 200) {
					onSuccess && onSuccess();
				} else if (response === 400) {
					notify.error('Nu am reușit să ștergem proiectul.');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getProjectPoster = useCallback(
		async (id: string) => {
			try {
				setLoadingCTA(true);
				const response = await fetchPosterQr(id);
				if (response.status !== 200) {
					throw new Error('Nu am reușit să accesăm posterul');
				}
				const fileUrl = window.URL.createObjectURL(response.data);
				window.open(fileUrl, '_blank');
			} catch (error) {
				notify.error(`${(error as Error).message}`);
			} finally {
				setLoadingCTA(false);
			}
		},
		[]
	);

	const checkProjectName = useCallback(
		async (params: ICheckProjectName, onSuccess?: () => void, onError?: () => void, fsp?: string) => {
			try {
				const response = await postProjectName(params, fsp);
				if (response === 200) {
					onSuccess && onSuccess();
				}
			} catch (error) {
				const message = error as unknown as { message: string };
				console.log(`${message?.message}`);
				onError && onError();
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const checkProjectGeometry = useCallback(
		async (params: any, onSuccess?: () => void, onError?: () => void, fsp?: string) => {
			try {
				const response = await postProjectGeometry(params, fsp);
				if (response === 200) {
					onSuccess && onSuccess();
				}
			} catch (error) {
				const message = error as unknown as { message: string };
				console.log(`${message?.message}`);
				onError && onError();
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	useEffect(() => {
		if (getProjectsOnRender && queryParam === undefined) {
			getProjects(getAllProj || false, page, items);
		}
		// eslint-disable-next-line
	}, [getProjectsOnRender, queryParam, page, items, getAllProj]);

	useEffect(() => {
		if (idParam) {
			getProject(idParam);
		}
		// eslint-disable-next-line
	}, [idParam]);

	useEffect(() => {
		if (queryParam && idParam === undefined) {
			getQueryProjects(getAllQuery || false, queryParam, page, items);
		} else if (idParam === undefined && enableQuery) {
			getProjects(getAllProj || false, page, items);
		}
		// eslint-disable-next-line
	}, [queryParam, idParam, page, items, getAllProj, getAllQuery]);

	return {
		data: data || ([] as IProject[] as T),
		setData: setData as stateSetter<T>,
		loading,
		getProjects,
		createProject,
		getProject,
		removeProject,
		loadingUpdate,
		loadingCTA,
		updateProject,
		getProjectPoster,
		checkProjectName,
		checkProjectGeometry,
		items,
		page,
		setItems,
		setPage,
		totalPages,
		totalItems
	};
}