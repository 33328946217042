import { styled, Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';

export const PageTitle = styled(Typography as FC<TypographyProps>)(({ theme }) => ({
	width: 'auto',
	maxWidth: 'calc(100% - 500px)',
	height: '56px',
	fontSize: '48px',
	lineHeight: '56px',
	color: theme.palette.primary.dark,
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
}));