import { Box } from '@mui/material';
import { Logo as LogoImage } from '@/assets';

interface ILogoProps {
  width?: string;
  height?: string;
  altImg?: string;
}

export const Logo: React.FC<ILogoProps> = ({ width, height, altImg }) => (
	<Box component="img" src={LogoImage} width={width || '114px'} 
		height={height || 'auto'} alt={altImg || 'black-logo'} />
);
