import { useCallback, useState } from 'react';
import { notify } from '@/utils';
import { patchAdminProfile, patchProfilePassword } from './actions';
import { IUpdateAdminProfile, IUpdateFSPProfile, IUpdatePasswordProfile, UseProfileReturnType } from './types';

export function useProfile(): UseProfileReturnType {
	const [loading, setLoading] = useState<boolean>(false);

	const editAdminProfile = useCallback(
		async (updatedObject: IUpdateAdminProfile, onSuccess?: () => void) => {
			try {
				setLoading(true);
				const response = await patchAdminProfile(updatedObject);
				if(response === 200) {
					onSuccess && onSuccess();
				} else if(response === 400) {
					notify.error('Nu am reușit să actualizăm profilul!');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[]
	);

	const editFSPProfile = useCallback(
		async (updatedObject: IUpdateFSPProfile, onSuccess?: () => void) => {
			try {
				setLoading(true);
				const response = await patchAdminProfile(updatedObject);
				if(response === 200) {
					onSuccess && onSuccess();
				} else if(response === 400) {
					notify.error('Nu am reușit să actualizăm profilul!');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[]
	);

	const editProfilePassword = useCallback(
		async (updatedObject: IUpdatePasswordProfile, onSuccess?: () => void) => {
			try {
				setLoading(true);
				const response = await patchProfilePassword(updatedObject);
				if(response === 200) {
					onSuccess && onSuccess();
				} else if(response === 400) {
					notify.error('Nu am reușit să actualizăm parola!');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[]
	);

	return {
		editAdminProfile,
		loading,
		editProfilePassword,
		editFSPProfile
	};
}